<script>
export default {
  name: "WindowComponent",
  props: {
    title: {
      type: String,
      default: 'Moje okno'
    },
    message: {
      type: String,
      default: 'Tu pojawi się notatka dopisana do zaznaczonego obiektu.'
    },
    show: {
      type: Boolean,
      default: false
    },
    initialX: {
      type: Number,
      default: 220
    },
    initialY: {
      type: Number,
      default: 120
    },
  },
  data() {
    return {
      isDragging: false,
      left: this.initialX,
      top: this.initialY,
      startX: 0,
      startY: 0
    }
  },
  computed: {
    windowStyle() {
      return {
        left: `${this.left}px`,
        top: `${this.top}px`
      }
    }
  },
  methods: {
    startDragging(e) {
      this.isDragging = true;
      this.startX = e.clientX - this.left;
      this.startY = e.clientY - this.top;
      document.addEventListener('mousemove', this.drag);
      document.addEventListener('mouseup', this.stopDragging);
    },
    stopDragging() {
      this.isDragging = false;
      document.removeEventListener('mousemove', this.drag);
      document.removeEventListener('mouseup', this.stopDragging);
    },
    drag(e) {
      if (this.isDragging) {
        this.left = e.clientX - this.startX;
        this.top = e.clientY - this.startY;
      }
    },
    closeWindow() {
      this.$emit('update:show', false);
    }
  },
  mounted() {
    document.addEventListener('mousemove', this.drag);
    document.addEventListener('mouseup', this.stopDragging);
  },
  beforeUnmount() {
    document.removeEventListener('mousemove', this.drag);
    document.removeEventListener('mouseup', this.stopDragging);
  }
}
</script>

<template>
  <div v-if="show" class="help-window" :style="windowStyle" ref="window">
    <div class="window-header" @mousedown="startDragging">
      <span class="window-title">{{ title }}</span>
      <span class="close-button" @click="closeWindow">&times;</span>
    </div>
    <div class="window-content">
      <slot></slot>
    </div>
  </div>
</template>

<style scoped>
.help-window {
  position: absolute;
  width: 280px;
  border: 1px solid var(--component-border-color);
  background-color: var(--component-background);
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  z-index: 9990;
}

.window-header {
  padding: 10px;
  cursor: move;
  background-color: var(--buttons-active-color);
  color: var(--inverse-text-color);
  font-weight: bold;
}

.close-button {
  float: right;
  cursor: pointer;
}

.window-content {
  overflow-y: scroll;
  display: flex;
  justify-content: center;
  padding: 10px;
  max-height: 300px;
  background-color: var(--dialog-background-color);
  color: var(--primary-text-color);
}

.window-content::-webkit-scrollbar {
  width: 1px;
}

.window-content::-webkit-scrollbar-thumb {
  background-color: var(--buttons-color);
  border-radius: var(--border-radius);
}

.window-content::-webkit-scrollbar-track {
  background: var(--primary-background);
  border-radius: var(--border-radius);
}

</style>