import { fabric } from 'fabric';

// Definicja warstw
const LAYERS = {
    OBUDOWY: 0,
    APARATURA: 1,
    ELEKTRYKA: 2,
    MASKOWNICE: 3,
    ELEWACJA: 4,
    NIEZALEZNY: 5
};

// Rozszerzenie prototypu fabric.Object
fabric.Object.prototype.activeIndex = LAYERS.OBUDOWY;

fabric.util.object.extend(fabric.Group.prototype, {
    toActiveSelection: function() {
        if (!this.canvas) {
            return null;
        }
        var canvas = this.canvas;
        var objects = this._objects;
        var options = fabric.Object.prototype.toObject.call(this);
        var activeSelection = new fabric.ActiveSelection([], {
            ...options
        });

        activeSelection.type = 'activeSelection';

        // const activeIndex = this.activeIndex;
        const drawingIndex = this.drawingIndex;

        canvas.remove(this);

        objects.forEach(function(object) {
            object.group = activeSelection;
            object.dirty = true;
            canvas.addObjectToLayer(object, object.activeIndex, drawingIndex);
        });
        activeSelection.canvas = canvas;
        activeSelection._objects = objects;

        canvas._activeObject = activeSelection;
        activeSelection.setCoords();
        canvas.requestRenderAll();

        return activeSelection;
    }
});

fabric.util.object.extend(fabric.ActiveSelection.prototype, {
    toGroup: function() {
        var objects = this._objects.concat();
        const zIndex = Math.min(...objects.map(obj => obj.activeIndex));
        const dIndex = Math.min(...objects.map(obj => obj.drawingIndex));
        this._objects = [];
        var options = fabric.Object.prototype.toObject.call(this);
        var newGroup = new fabric.Group([]);
        delete options.type;
        newGroup.set(options);
        objects.forEach(function(object) {
            object.canvas.remove(object);
            object.group = newGroup;
        });
        newGroup._objects = objects;
        if (!this.canvas) {
            return newGroup;
        }
        var canvas = this.canvas;
        canvas.addObjectToLayer(newGroup, zIndex, dIndex);
        canvas._activeObject = newGroup;
        newGroup.setCoords();
        return newGroup;
    }
});

// Rozszerzenie prototypu fabric.Canvas
fabric.util.object.extend(fabric.Canvas.prototype, {
    sendToBack: function(object) {
        if (!object) return this;

        const activeSelection = this._activeObject;
        const canvas = this;
        let objIndex, objs, i;

        const objectOrder = function(object, canvas) {
            objIndex = canvas._objects.indexOf(object);
            if (objIndex > -1) {
                canvas._objects.splice(objIndex, 1);

                const firstIndex = canvas._objects.findIndex(obj => obj.activeIndex === object.activeIndex);

                canvas._objects.splice(firstIndex, 0, object);
            }
        }

        if (object === activeSelection && object.type === 'activeSelection') {
            objs = activeSelection._objects;
            for (i = objs.length; i--;) {
                objectOrder(objs[i], canvas);
            }
        } else {
            objectOrder(object, canvas)
        }

        return this.requestRenderAll();
    },

    bringToFront: function(object) {
        if (!object) return this;

        const activeSelection = this._activeObject;
        const canvas = this;
        let objIndex, objs, i;


        const objectOrder = function(object, canvas) {
            objIndex = canvas._objects.indexOf(object);
            if (objIndex > -1) {
                canvas._objects.splice(objIndex, 1);

                const lastIndex = canvas._objects.reduce((lastIndex, obj, index) =>
                    (obj.activeIndex === object.activeIndex ? index : lastIndex), -1);

                canvas._objects.splice(lastIndex + 1, 0, object);
            }
        }

        if (object === activeSelection && object.type === 'activeSelection') {
            objs = activeSelection._objects;
            for (i = objs.length; i--;) {
                objectOrder(objs[i], canvas);
            }
        } else {
            objectOrder(object, canvas)
        }

        return this.requestRenderAll();
    },

    sortObjectsByZIndex: function() {
        this._objects.sort((a, b) => {
            const zIndexA = a.activeIndex ?? 0;
            const zIndexB = b.activeIndex ?? 0;
            return zIndexA - zIndexB || this._objects.indexOf(a) - this._objects.indexOf(b);
        });

        return this.requestRenderAll();
    },

    createGroup: function(objects, options, layer, draw) {
        const group = new fabric.Group(objects, options);
        group.activeIndex = Math.max(...objects.map(obj => obj.activeIndex));
        this.addObjectToLayer(group, layer, draw);
        return this.sortObjectsByZIndex();
    },

    toGroup: function(objects, options) {
        const group = fabric.Group.prototype.toGroup.call(this, objects, options);
        group.activeIndex = Math.max(...objects.map(obj => obj.activeIndex));
        return this.sortObjectsByZIndex();
    },

    addObjectToLayer: function(object, layerIdx, drawIdx) {
        object.activeIndex = layerIdx;
        object.drawingIndex = drawIdx;
        this.add(object);
        return this.sortObjectsByZIndex();
    }
});

export { LAYERS };
