<template>
  <HistoryComponent v-if="myCanvas" :canvas="myCanvas" />
  <button @click="handleAddNote" :disabled="!isSelectedOne" title="Dodaj notatkę" class="rect-button" style="margin-right: 8px;">
    <img src="@/assets/icons/note.svg" alt="Dodaj notatkę" :class="{ 'inverted': $theme.invertIcon }"/>
  </button>
  <button @click="handleRotateLeft" :disabled="!isSelected" title="Obrót o 90° w lewo" class="rect-button">
    <img src="@/assets/icons/rotate_left.svg" alt="Obrót o 90° w lewo" :class="{ 'inverted': $theme.invertIcon }"/>
  </button>
  <button @click="handleZeroDegree" :disabled="!isSelected" title="Powrót do początkowego kąta" class="rect-button">
    <img src="@/assets/icons/zero_degree.svg" alt="Powrót do początkowego kąta" :class="{ 'inverted': $theme.invertIcon }"/>
  </button>
  <button @click="handleRotateRight" :disabled="!isSelected" title="Obrót o 90° w prawo" class="rect-button" style="margin-right: 10px;">
    <img src="@/assets/icons/rotate_right.svg" alt="Obrót o 90° w prawo" :class="{ 'inverted': $theme.invertIcon }"/>
  </button>
  <ObjectsManipulation
      ref="ObjectsManipulation"
      :disable_buttons="!isSelected"
      :canvas="myCanvas"
      :selected-objects="selectedObjects"
      :groups_enabled="groupCreationDisabled"
  />
  <ObjectsAlignment :disable_buttons="!isSelected"
                    @align-close="handleCloseObjects"
                    @align-to-left="handleAlignToLeft"
                    @align-to-right="handleAlignToRight"
                    @align-to-top="handleAlignToTop"
                    @align-to-bottom="handleAlignToBottom"
                    @align-to-center-vertical="handleAlignToCenterVertical"
                    @align-to-center-horizontal="handleAlignToCenterHorizontal"
  />
  <LineSelector @line-size-selected="handleLineSizeSelected" />
  <ColorSelector @color-selected="onColorPickerClick" />
</template>

<script>
import ObjectsManipulation from "@/components/ObjectsManipulation.vue";
import HistoryComponent from "@/components/HistoryComponent.vue";
import LineSelector from "@/components/LineSelector.vue";
import ColorSelector from "@/components/ColorSelector.vue";
import ObjectsAlignment from "@/components/ObjectsAligment.vue";

export default {
  components: {
    ColorSelector,
    LineSelector,
    ObjectsAlignment,
    ObjectsManipulation,
    HistoryComponent,
  },
  props: {
    myCanvas: Object,
    selectedObjects: Object,
    isSelectedOne: Boolean,
    isSelected: Boolean,
    groupCreationDisabled: Boolean
  },
  data() {
    return {
      canvasHook: null,
      activeObjects: null,
    }
  },
  watch: {
    myCanvas: {
      immediate: true,
      handler(newCanvas) {
        if (newCanvas) {
          this.canvasHook = newCanvas;
        }
      },
    },
    selectedObjects: {
      immediate: true,
      handler(newBag) {
        if (newBag) {
          this.activeObjects = newBag;
        }
      },
    },
  },
  emits: [
    'add-note',
    'rotate-left',
    'zero-degree',
    'rotate-right',
    'align-close',
    'align-to-left',
    'align-to-right',
    'align-to-top',
    'align-to-bottom',
    'align-to-center-vertical',
    'align-to-center-horizontal',
    'line-size-selected',
    'color-selected'
  ],
  methods: {
    clearClipboard() {
      this.$refs.ObjectsManipulation.clearClipboard();
    },
    handleAddNote() {
      this.$emit('add-note');
    },
    handleRotateLeft() {
      this.$emit('rotate-left');
    },
    handleZeroDegree() {
      this.$emit('zero-degree');
    },
    handleRotateRight() {
      this.$emit('rotate-right');
    },
    handleCloseObjects() {
      this.$emit('align-close');
    },
    handleAlignToLeft() {
      this.$emit('align-to-left');
    },
    handleAlignToRight() {
      this.$emit('align-to-right');
    },
    handleAlignToTop() {
      this.$emit('align-to-top');
    },
    handleAlignToBottom() {
      this.$emit('align-to-bottom');
    },
    handleAlignToCenterVertical() {
      this.$emit('align-to-center-vertical');
    },
    handleAlignToCenterHorizontal() {
      this.$emit('align-to-center-horizontal');
    },
    handleLineSizeSelected(size) {
      this.$emit('line-size-selected', size);
    },
    onColorPickerClick(color) {
      this.$emit('color-selected', color);
    }
  }
}
</script>

<style scoped>
@import '@/assets/themes.css';

input[type="text"] {
  position: absolute;
  font-size: 20px;
  outline: none;
  background: transparent;
  font-family: inherit;
}


</style>
