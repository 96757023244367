<template>
  <div class="parent-padlock">
    <button @click="toggleState" :disabled="isDisable"
            class="rect-button default-background" :class="{ 'active': isOpen }"
            title="Zablokuj zaznaczanie do wybranej warstwy. (Alt + B)">
      <img :src="require(`@/assets/icons/${currentIcon}.svg`)" alt="icon" :class="{ 'inverted': $theme.invertIcon }"/>
    </button>
  </div>
</template>

<script>
export default {
  name: 'ToggleButton',
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    isDisable: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    currentIcon() {
      return this.isOpen ? 'padlock_open' : 'padlock_close'; //
    }
  },
  methods: {
    toggleState() {
      this.$emit('update:isOpen', !this.isOpen);
    }
  }
}
</script>

<style scoped>
@import '@/assets/themes.css';

.parent-padlock {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 0;
  padding: 4px;
}

.default-background {
  background-color: var(--buttons-lock-color);
}

</style>