<script>
import { fabric } from 'fabric';
// import { v4 as uv4 } from 'uuid';
import '@/utils/fabric_extensions';
import {
  createRectangle,
  createEllipse,
  createArrow,
  createMeasure,
  createQuadraticBezierCurve,
  createSimpleLine
} from '@/utils/shapes';
import {
  alignLeft,
  alignTop,
  alignRight,
  alignBottom,
  alignCenterVertical,
  alignCenterHorizontal,
  alignCloseObjects
} from '@/utils/geometry';
import {
  deserializeCanvas,
  deserializeFileToCanvas,
  saveAllCanvasesToFile,
  saveCanvas,
  saveCanvasToFile
} from '@/utils/canvas_serialization';
import {showNoteInput} from "@/utils/notes"; // import {showNoteInput, noteIconPosition, updateNoteIconPosition} from "@/utils/notes";
import {generateA3PDF, generateA3SVG, generateA4PDF, generateNotesTable} from '@/utils/files_generator'
import { mapState, mapActions } from 'vuex';
import {markRaw, nextTick} from "vue";
import {addIcon} from "@/utils/add_icon";
import {APP_SETTINGS, CANVAS_SIZES} from "@/utils/constants";
import ModalComponent from "@/components/ModalComponent.vue";

export default {
  components: {ModalComponent},
  emits: ['update-message', 'canvas-created', 'viewport-updated', 'padlock-updated', 'selections-flag-updated', 'selected-object-updated', 'group-creation-updated', 'project-name', 'add-new-button', 'reset-buttons'],
  data() {
    return {
      myCanvas: null,
      initialDOM: null,
      canvases: [],
      actualCanvas: 0,
      activeDrawingName: "Rysunek 1",
      viewportWidth: CANVAS_SIZES.WIDTH,  // for A3 300dpi
      viewportHeight: CANVAS_SIZES.HEIGHT,
      lastLayerId: 5,
      activeObjects: [],
      currentDragData: null,
      pickColor: 'rgba(0, 0, 0, 255)',
      strokeWidth: 2,
      canvasJSON: '',
      showTextInput: false,
      inputText: '',
      textInputStyle: {
        position: 'absolute',
        top: '0px',
        left: '0px',
        fontSize: '20px',
        display: 'none'
      },
      currentText: null,
      moveMode: false,
      isDirty: false,
      isLastActive: false,
      layerSelection: false,
      isGlobalSelectOpen: false,
      drawingIndex: 0,
      activeIndex: 0,
      textDropPoint: {x: 100, y: 100},
      isModalVisible: false,
      showButtons: false,
      redrawing: false,
      modalMessage: "Trwa generowanie",
    };
  },
  computed: {
    ...mapState(['isClearDemanded', 'isDeserializeDemanded', 'isSerializeDemanded', 'isPDFDemanded', 'isPrintDemanded', 'isNotesDemanded', 'iPDFTypeDemanded', 'isSVGDemanded', 'iSVGTypeDemanded', 'projectName', "isWholeProject", "canvasID", "canvasIDToClear", "canvasButtons" ]),
    canvasesIDs() {
      return Array.from({ length: 10 }, (_, i) => `canvas${i}`);
    },
    isSelectedOne() {
      return this.activeObjects && !Array.isArray(this.activeObjects) && this.activeObjects.type !== 'activeSelection';
    },
    isSelected() {  // one or more
      return this.activeObjects && !Array.isArray(this.activeObjects);
    },
    padlockButtonDisabled() {
      return this.activeIndex === this.lastLayerId-1;
    },
    groupCreationDisabled() {
      return this.isGlobalSelectOpen || !this.isSelected;
    }
  },
  async mounted() {
    this.initialDOM = document.documentElement.cloneNode(true);
    this.addNewCanvasIfNecessary(this.actualCanvas);
    this.myCanvas = markRaw(this.canvases[this.actualCanvas]);
    console.log("Fabric: " + fabric.version);

    // const companyMarksGroup = await this.addCompanyMarks(this.projectName, this.activeDrawingName);
    // this.myCanvas.addObjectToLayer(companyMarksGroup, this.activeIndex, this.actualCanvas);

    this.$emit('canvas-created', this.myCanvas);
    this.resizeCanvas();

    this.$emit('viewport-updated', {
      width: this.viewportWidth,
      height: this.viewportHeight,
    });
    this.$emit('padlock-updated', {
      isGlobalSelectOpen: this.isGlobalSelectOpen,
      padlockButtonDisabled: this.padlockButtonDisabled,
    });

    window.addEventListener('resize', this.resizeCanvas);
    window.addEventListener('keydown', this.handleKeyDown);

    this.$watch('isClearDemanded', (demand) => {
      if (demand) {
        this.clearCanvas(this.canvasIDToClear);
        this.resetCanvas();
      }
    });

    this.$watch('isDeserializeDemanded', (demand) => {
      if (demand) {
        this.isModalVisible = true;
        this.deserializeCanvas();
        this.deserializeReady();
        this.isModalVisible = false;
      }
    });

    this.$watch('isSerializeDemanded', (demand) => {
      if (demand) {
        this.isModalVisible = true;
        this.serializeCanvas(this.isWholeProject);
        this.serializeReady();
      }
    });

    this.$watch('isPDFDemanded', (demand) => {
      if (demand) {
        this.isModalVisible = true;
        this.generatePDF(this.iPDFTypeDemanded, this.projectName);
        this.pdfReady();
      }
    });

    this.$watch('isSVGDemanded', (demand) => {
      if (demand) {
        this.isModalVisible = true;
        this.generateSVG(this.iSVGTypeDemanded, this.projectName);
        this.svgReady();
      }
    });

    this.$watch('isPrintDemanded', (demand) => {
      if (demand) {
        this.isModalVisible = true;
        this.printCanvas(this.iPDFTypeDemanded);
        this.printReady();
      }
    });

    this.$watch('isNotesDemanded', (demand) => {
      if (demand) {
        this.printNotes();
        this.notesReady();
      }
    });
  },
  watch: {
    '$theme.isDarkTheme': {
      handler() {
        if (this.myCanvas) {
          const boundary = this.myCanvas.getObjects().find(obj => obj.name === 'boundaryFrame');
          boundary.stroke = this.$theme.isDarkTheme ? "white" : "black";

          boundary.dirty = true;
          this.myCanvas.requestRenderAll();
        }
      },
      immediate: true
    },
  },
  methods: {
    ...mapActions(['resetCanvas', 'deserializeReady', 'serializeReady', 'pdfReady', 'printReady', 'notesReady', 'svgReady']),
    updateDrawingName(activeDrawingName) {
      this.activeDrawingName = activeDrawingName;
    },
    updateDrawingIndex(index, activeDrawingName, deepCopy=false) {
      this.deselectAllObjects();
      this.$refs[this.canvasesIDs[this.actualCanvas]][0].parentNode.style.display = 'none';

      this.drawingIndex = index;
      this.actualCanvas = index;
      this.activeDrawingName = activeDrawingName;

      this.addNewCanvasIfNecessary(index, deepCopy);
      this.myCanvas = markRaw(this.canvases[index]);

      this.$emit('canvas-created', this.myCanvas);

      this.$refs[this.canvasesIDs[this.actualCanvas]][0].parentNode.style.display = 'block';

      const objects = this.myCanvas.getObjects();
      this.checkObjectsVisibility(this.activeIndex, objects);
      this.resizeCanvas();
    },
    updateActiveIndex(idx, isLastActive) {
      const objects = this.myCanvas.getObjects();
      const moveModeActive = this.moveMode;
      if (moveModeActive) {
        this.handleMoveActive(false)
      }
      this.deselectAllObjects();
      if (this.activeIndex !== idx) {
        if (this.lastLayerId !== idx) {
          this.checkObjectsVisibility(idx, objects);
        }
        this.activeIndex = idx;

        if (idx === this.lastLayerId-1 && this.isGlobalSelectOpen) {
          this.switchGlobalSelectOpen(false);
        } else {
          this.switchGlobalSelectOpen(this.isGlobalSelectOpen);
        }
      }
      if (this.isLastActive !== isLastActive) {
        this.checkLastLayerVisibility(isLastActive, objects);
        this.isLastActive = isLastActive;
      }
      if (moveModeActive) {
        this.handleMoveActive(true)
      }
    },
    // eslint-disable-next-line no-unused-vars
    setDirtyFromOutside(target) {
      this.isDirty = true;
    },
    switchGlobalSelectOpen(flag) {
      this.isGlobalSelectOpen = flag;
      this.$emit('padlock-updated', {
        isGlobalSelectOpen: this.isGlobalSelectOpen,
        padlockButtonDisabled: this.padlockButtonDisabled,
      });
    },
    updateAttachedPosition(object) {
      if (object.parentId) {
        const parentObject = this.myCanvas.getObjects().find(obj => obj.id === object.parentId);
        if (parentObject && object.name !== 'handler') {
          object.set({left: parentObject.left, top: parentObject.top, angle: parentObject.angle});
          object.setCoords();
        }
      }
    },
    checkObjectsSelectability(index, object) {
      if (!object.lockSelectability && ((this.isGlobalSelectOpen && object.activeIndex >= 0) || object.activeIndex === index)) {
        object.set('selectable', true);
      } else {
        object.set('selectable', false);
      }
    },
    checkObjectsVisibility(idx, objects) {
      objects.forEach(obj => {
        if (obj.activeIndex <= idx) {
          obj.set('visible', true);
          this.updateAttachedPosition(obj)
          this.checkObjectsSelectability(idx, obj);
        } else if (obj.activeIndex !== this.lastLayerId) {
          obj.set('visible', false);
        }
      });
      this.myCanvas.requestRenderAll();
    },
    checkLastLayerVisibility(isLastActive, objects) {
      objects.forEach(obj => {
        if (obj.activeIndex === this.lastLayerId) {
          if (isLastActive) {
            obj.set('visible', true);
            this.updateAttachedPosition(obj)
            this.checkObjectsSelectability(this.lastLayerId, obj);
          } else {
            obj.set('visible', false);
          }
        }
      });
      this.myCanvas.requestRenderAll();
    },
    changeSelectableForObjects(event) {
      this.deselectAllObjects();
      this.switchGlobalSelectOpen(event);
      const objects = this.myCanvas.getObjects();
      objects.forEach(obj => {
        if (obj.visible) {
          this.checkObjectsSelectability(this.activeIndex, obj);
        }
      });
      this.myCanvas.requestRenderAll();
    },
    clearAllButtons() {
      this.$emit('reset-buttons');
    },
    addNewButtonIfNecessary(indexOfCanvas, buttonLabel) {
      this.$emit('add-new-button', indexOfCanvas, buttonLabel);
    },
    addNewCanvasIfNecessary(index, deepCopy=false){
      let jsonData = '';
      let myCanvas = null;
      let result = false;

      if (deepCopy) {
        jsonData = saveCanvas(this.myCanvas);
      }

      fabric.Object.prototype.borderColor = 'green';
      fabric.Object.prototype.cornerColor = 'green';
      fabric.Object.prototype.cornerStrokeColor = 'green';

      if (this.canvases.length <= index) {
        myCanvas = new fabric.Canvas(this.canvasesIDs[index], {
          selection: true,
          defaultCursor: 'default',
          hoverCursor: 'pointer',
          selectionFullyContained: true,
          perPixelTargetFind: true,
          targetFindTolerance: 5,
        });

        const canvasElement = myCanvas.getElement();
        canvasElement.getContext('2d', { willReadFrequently: true });

        result = true;

        myCanvas.lowerCanvasEl.addEventListener('contextmenu', (e) => {
          e.preventDefault();
        }, false);
        myCanvas.fireRightClick = true;
        myCanvas.stopContextMenu = true;

        myCanvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
        myCanvas.setZoom(0.8);

        this.addBoundaryFrame(myCanvas, this.viewportWidth, this.viewportHeight);
        this.setupCanvasEvents(myCanvas);

        this.canvases.push(markRaw(myCanvas));
      } else {
        myCanvas = this.canvases[index];
      }
      if (deepCopy && myCanvas) {
        deserializeCanvas(myCanvas, jsonData);
      }
      return result;
    },
    limitPan(vpt, innerWidth, innerHeight, outerWidth, outerHeight) {
      if (innerWidth <= outerWidth) {
        vpt[4] = (outerWidth - innerWidth) / 2;
      } else {
        if (vpt[4] > 0) vpt[4] = 0;
        if (vpt[4] < outerWidth - innerWidth) vpt[4] = outerWidth - innerWidth;
      }

      if (innerHeight <= outerHeight) {
        vpt[5] = (outerHeight - innerHeight) / 2;
      } else {
        if (vpt[5] > 0) vpt[5] = 0;
        if (vpt[5] < outerHeight - innerHeight) vpt[5] = outerHeight - innerHeight;
      }
    },
    zoomToPointOnCanvas(point, canvas) {
      const newZoom = 0.8;
      const outerWidth = this.myCanvas.getWidth();
      const outerHeight = this.myCanvas.getHeight();
      const innerWidth = this.viewportWidth * newZoom;
      const innerHeight = this.viewportHeight * newZoom;

      // For point of click to move this point to center
      const vpt = canvas.viewportTransform;

      const x = point.x;
      const y = point.y;

      const zoomPoint = new fabric.Point(x, y);

      canvas.zoomToPoint(zoomPoint, newZoom);

      this.limitPan(vpt, innerWidth, innerHeight, outerWidth, outerHeight);

      this.myCanvas.requestRenderAll();
    },
    setupCanvasEvents(myCanvas) {
      myCanvas.on('mouse:dblclick', (event) => {
        const pointer = myCanvas.getPointer(event.e, true);
        this.zoomToPointOnCanvas(pointer, myCanvas);
      });

      myCanvas.on('selection:created', (e) => {
        this.activeObjects = markRaw(myCanvas.getActiveObject());

        if (e.selected.length > 1 && this.activeObjects.type === 'activeSelection') {
          this.activeObjects.lockScalingX = true;
          this.activeObjects.lockScalingY = true;
          this.activeObjects.lockRotation = true;
          this.activeObjects.setControlsVisibility({
            mt: false,
            mb: false,
            ml: false,
            mr: false,
            tl: false,
            tr: false,
            bl: false,
            br: false,
            mtr: false
          });
        }
        else if (e.selected.length === 1 && this.activeObjects.note) {
          this.$emit('update-message', this.activeObjects.note);
        } else {
          this.$emit('update-message', '');
        }

        this.$emit('selections-flag-updated', {
          isSelected: this.isSelected,
          isSelectedOne: this.isSelectedOne,
        });
        this.$emit('selected-object-updated', this.activeObjects);
        this.$emit('group-creation-updated', this.groupCreationDisabled);
      });

      myCanvas.on('selection:updated', (e) => {
        const alreadySelected = !Array.isArray(this.activeObjects);
        this.activeObjects = markRaw(myCanvas.getActiveObject());
        if ((alreadySelected || e.selected.length > 1) && this.activeObjects.type === 'activeSelection') {
          this.activeObjects.lockScalingX = true;
          this.activeObjects.lockScalingY = true;
          this.activeObjects.lockRotation = true;
          this.activeObjects.setControlsVisibility({
            mt: false,
            mb: false,
            ml: false,
            mr: false,
            tl: false,
            tr: false,
            bl: false,
            br: false,
            mtr: false
          });
        }
        else if (e.selected.length === 1 && this.activeObjects.note) {
          this.$emit('update-message', this.activeObjects.note);
        } else {
          this.$emit('update-message', '');
        }
        this.$emit('selections-flag-updated', {
          isSelected: this.isSelected,
          isSelectedOne: this.isSelectedOne,
        });
        this.$emit('selected-object-updated', this.activeObjects);
        this.$emit('group-creation-updated', this.groupCreationDisabled);
      });

      myCanvas.on('selection:cleared', () => {
        this.activeObjects = [];
        this.$emit('update-message', '');
        this.$emit('selections-flag-updated', {
          isSelected: this.isSelected,
          isSelectedOne: this.isSelectedOne,
        });
        this.$emit('selected-object-updated', this.activeObjects);
        this.$emit('group-creation-updated', this.groupCreationDisabled);
      });

      myCanvas.on({
        'object:moving': this.handleMoving,
        'object:scaling': this.handleScaling,
        'object:rotating': this.handleNoteIcon,
        'object:state:isDirty': (e) => {this.setDirtyFromOutside(e.target)},
      });

      myCanvas.on('mouse:wheel', this.handleMouseWheel);
      myCanvas.on('mouse:move', this.handleMouseMove);
      myCanvas.on('mouse:down', this.handleMouseDown);
      myCanvas.on('mouse:up', this.handleMouseUp);
    },
    handleMoveActive(newValue) {
      this.moveMode = newValue;
      this.deselectAllObjects();
      const objects = this.myCanvas.getObjects();

      if (newValue) {
        this.myCanvas.defaultCursor = 'grab';
        this.myCanvas.hoverCursor = 'grab';
        objects.forEach(obj => {
          if (obj.selectable) {
            obj.selectable = false;
          }
        });
      } else {
        this.myCanvas.defaultCursor = 'default';
        this.myCanvas.hoverCursor = 'pointer';
        objects.forEach(obj => {
          if (obj.visible) {
            this.checkObjectsSelectability(this.activeIndex, obj);
          }
        });
      }

    },
    handleMouseWheel(opt) {
      const delta = opt.e.deltaY;
      let zoom = this.myCanvas.getZoom();
      let newZoom = zoom * 0.9995 ** delta;
      if (newZoom > 8) newZoom = 8;

      const outerWidth = this.myCanvas.getWidth();
      const outerHeight = this.myCanvas.getHeight();
      const innerWidth = this.viewportWidth * newZoom;
      const innerHeight = this.viewportHeight * newZoom;

      if (innerWidth + 20 > outerWidth || innerHeight + 20 > outerHeight) {
        zoom = newZoom;
      }

      const pointer = this.myCanvas.getPointer(opt.e, true);
      const zoomPoint = new fabric.Point(pointer.x, pointer.y);

      this.myCanvas.zoomToPoint(zoomPoint, zoom);

      const vpt = this.myCanvas.viewportTransform;

      this.limitPan(vpt, innerWidth, innerHeight, outerWidth, outerHeight);

      this.myCanvas.getObjects().forEach((obj) => {
        obj.setCoords();
      });

      this.myCanvas.requestRenderAll();
      opt.e.preventDefault();
      opt.e.stopPropagation();
    },
    handleMouseDown(opt) {
      const evt = opt.e;
      // const target = opt.target;
      if (evt && evt.altKey === true || this.moveMode || opt.button === 3) {
        this.isDragging = true;
        this.myCanvas.selection = false;
        this.myCanvas.defaultCursor = 'grab';
        this.myCanvas.hoverCursor = 'grab';
        this.lastPosX = evt.clientX;
        this.lastPosY = evt.clientY;
      } else if (this.isDirty) {
        this.isDirty = false;
      } else {
        this.myCanvas.fire('object:mouse:down:check', { target: this.activeObjects });
      }
    },
    handleMouseMove(opt) {
      if (this.isDragging) {
        const e = opt.e;
        const vpt = this.myCanvas.viewportTransform;
        vpt[4] += e.clientX - this.lastPosX;
        vpt[5] += e.clientY - this.lastPosY;

        const outerWidth = this.myCanvas.getWidth();
        const outerHeight = this.myCanvas.getHeight();
        const innerWidth = this.viewportWidth * this.myCanvas.getZoom();
        const innerHeight = this.viewportHeight * this.myCanvas.getZoom();

        if (innerWidth <= outerWidth) {
          vpt[4] = (outerWidth - innerWidth) / 2;
        } else {
          if (vpt[4] > 0) vpt[4] = 0;
          if (vpt[4] < outerWidth - innerWidth) vpt[4] = outerWidth - innerWidth;
        }

        if (innerHeight <= outerHeight) {
          vpt[5] = (outerHeight - innerHeight) / 2;
        } else {
          if (vpt[5] > 0) vpt[5] = 0;
          if (vpt[5] < outerHeight - innerHeight) vpt[5] = outerHeight - innerHeight;
        }

        this.myCanvas.requestRenderAll();
        this.lastPosX = e.clientX;
        this.lastPosY = e.clientY;
      }

    },
    // eslint-disable-next-line no-unused-vars
    handleMouseUp(opt) {
      //const target = this.myCanvas.findTarget(event.e, false);
      if (this.isDragging) {
        this.isDragging = false;
        this.myCanvas.selection = true;
        this.myCanvas.defaultCursor = 'default';
        this.myCanvas.hoverCursor = 'pointer';
      }
      if (opt.button === 3) {
        opt.e.preventDefault();
        this.selectAllObjects();
        this.deselectAllObjects();
      }
      this.myCanvas.fire('object:mouse:up:check', { target: this.activeObjects });
    },
    onColorPickerClick(color) {
      const o = this.activeObjects
      this.pickColor = color

      const changeColor = (o) => {
        if(o.isFromBase) return;
        if (o.name !== 'handler') {
          const color = this.pickColor;
          o.set({stroke: color});
          if (o.type === 'group') {
            o.forEachObject(function(obj) {
              obj.set({stroke: color});
              if (obj.fill && obj.fill !== '' && obj.fill !== 'transparent') {
                obj.set({fill: color});
              }
            });
          }
          if (o.fill && o.fill !== '' && o.fill !== 'transparent') {
            o.set({fill: color});
          }
        } else {
          const value = o.parentId;
          const objectsToColor = this.myCanvas.getObjects().filter(obj => obj.id === value || obj.parentId === value);
          objectsToColor.forEach(obj => {
            obj.set({stroke: this.pickColor});
          });
        }
        this.myCanvas.fire('object:state:changed', {target: o});
      }
      if (o && !Array.isArray(o)) {
        if (o.type === 'activeSelection') {
          o.getObjects().forEach(obj => changeColor(obj));
        } else {
          changeColor(o);
        }
        this.myCanvas.requestRenderAll();
      }
    },
    handleLineSizeSelected(size) {
      this.strokeWidth = size;
      const o = this.activeObjects

      const changeLine = (o) => {
        if (o.name !== 'handler') {
          if (o.name === 'Text') {
            o.set({strokeWidth: this.strokeWidth/10});
          } else {
            o.set({strokeWidth: this.strokeWidth});
          }
        } else {
          const value = o.parentId;
          const objectsToColor = this.myCanvas.getObjects().filter(obj => obj.id === value);
          objectsToColor.forEach(obj => {
            obj.set({strokeWidth: this.strokeWidth});
          });
        }
        this.myCanvas.fire('object:state:changed', {target: o});
      }

      if (o && !Array.isArray(o)) {
        if (o.type === 'activeSelection') {
          o.getObjects().forEach(obj => changeLine(obj));
        } else {
          changeLine(o);
        }
        this.myCanvas.requestRenderAll();
      }
    },
    addShapeToActiveDrawing(shape) {
      this.myCanvas.addObjectToLayer(shape, this.activeIndex, this.drawingIndex);
      shape.setCoords();
      if (this.moveMode){
        shape.selectable = false;
      }
      this.myCanvas.requestRenderAll();
    },
    addBoundaryFrame(myCanvas, width, height, left=0, top=0, name="boundaryFrame", color=null, strokeWidth=2) {
      let frameColor = color;

      if(!frameColor) {
        frameColor = this.$theme.isDarkTheme? 'white' : 'black'
      }

      const boundary = new fabric.Rect({
        left: left,
        top: top,
        fill: 'transparent',
        stroke: frameColor,
        strokeWidth: strokeWidth,
        width: width-1,
        height: height-1,
        selectable: false,
        evented: false,
        name: name
      });

      if(myCanvas) {
        myCanvas.addObjectToLayer(boundary, -1, this.drawingIndex);
        myCanvas.requestRenderAll();
      }

      return boundary;
    },
    async getCompanyMarksGroup(companyMarksGroup, projectName, drawingName) {
      const svgUrl = require('@/assets/inco_table.svg');

      return new Promise((resolve) => {
        fabric.loadSVGFromURL(svgUrl, (objects, options) => {
          const svg = fabric.util.groupSVGElements(objects, options);
          // svg.scaleToWidth(480);
          svg.set({
            left: -1650,
            top: -150
          });
          companyMarksGroup.add(svg);

          const companyText = `${drawingName}\n\n${projectName}\n`;
          const versionText = `Wersja:   ${APP_SETTINGS.VERSION}`;
          console.log(versionText);

          const textObj = new fabric.Text(companyText, {
            left: 400,
            top: -100,
            fill: 'black',
            fontSize: 72,
            stroke: 'black',
            fontFamily: 'Open Sans, sans-serif',
            fontWeight: '300',
            fontStyle: 'italic',
            strokeWidth: 0.1,
          });
          // const verObj = new fabric.Text(versionText, {
          //   left: -792,
          //   top: 118,
          //   fill: 'black',
          //   fontSize: 50,
          //   stroke: 'black',
          //   fontFamily: 'Montserrat, sans-serif',
          //   fontStyle: 'italic',
          //   strokeWidth: 1,
          // });

          companyMarksGroup.add(textObj);
          // companyMarksGroup.add(verObj);

          resolve(companyMarksGroup);
        });
      });
    },
    async addCompanyMarks(projectName, drawingName) {
      const companyMarksGroup = new fabric.Group();
      companyMarksGroup.set({
        left: 3740,
        top: 4495,
        width: 3300,
        height: 500,
      });
      companyMarksGroup.setCoords();

      await this.getCompanyMarksGroup(companyMarksGroup, projectName, drawingName);

      return companyMarksGroup;
    },
    addArrow(left, top) {
      const arrow = createArrow(left, top, this.pickColor, this.strokeWidth);
      this.addShapeToActiveDrawing(arrow);
    },
    addSimpleLine(left, top) {
      const line = createSimpleLine(left, top, this.pickColor, this.strokeWidth);
      this.addShapeToActiveDrawing(line);
    },
    addMeasure(left, top) {
      const measure = createMeasure(left, top, this.pickColor, this.strokeWidth);
      this.addShapeToActiveDrawing(measure);
    },
    // addZigLine(left, top) {
    //   const { bezierCurve, startPoint, controlPoint, endPoint } = createQuadraticBezierCurve(left, top, this.pickColor, this.strokeWidth, this.myCanvas);
    //
    //   startPoint.set('id', uv4());
    //   controlPoint.set('id', uv4());
    //   endPoint.set('id', uv4());
    //   bezierCurve.set('id', uv4());
    //
    //   startPoint.set('parentId', bezierCurve.id);
    //   controlPoint.set('parentId', bezierCurve.id);
    //   endPoint.set('parentId', bezierCurve.id);
    //   bezierCurve.set('controlPoints', [startPoint.id, controlPoint.id, endPoint.id]);
    //
    //   this.myCanvas.addObjectToLayer(startPoint, this.activeIndex, this.drawingIndex);
    //   this.myCanvas.addObjectToLayer(controlPoint, this.activeIndex, this.drawingIndex);
    //   this.myCanvas.addObjectToLayer(endPoint, this.activeIndex, this.drawingIndex);
    //
    //   this.addShapeToActiveDrawing(bezierCurve);
    // },
    addZigLine(left, top) {
      const line = createQuadraticBezierCurve(left, top, this.pickColor, this.strokeWidth);
      this.addShapeToActiveDrawing(line);
    },
    addRectangle(left, top) {
      const rect = createRectangle(left, top, this.pickColor, this.strokeWidth);
      this.addShapeToActiveDrawing(rect);
    },
    addEllipse(left, top) {
      const ellipse = createEllipse(left, top, this.pickColor, this.strokeWidth);
      this.addShapeToActiveDrawing(ellipse);
    },
    addRectangleFill(left, top) {
      const rect = createRectangle(left, top, this.pickColor, this.strokeWidth, true);
      this.addShapeToActiveDrawing(rect);
    },
    addEllipseFill(left, top) {
      const ellipse = createEllipse(left, top, this.pickColor, this.strokeWidth, true);
      this.addShapeToActiveDrawing(ellipse);
    },
    // eslint-disable-next-line no-unused-vars
    addIcon(name, svgText, left, top, editable_h, editable_v, rotation, active_index, visible=true, selectable=true) {
      return addIcon(this.myCanvas, name, svgText, left, top, editable_v, editable_h, rotation, active_index, this.drawingIndex, visible, selectable);
    },
    onDragOver(event) {
      event.preventDefault();
    },
    onDrop(event) {
      event.preventDefault();
      this.deselectAllObjects();
      const shapeType = event.dataTransfer.getData('shapeType');
      const canvasElement = this.$refs[this.canvasesIDs[this.actualCanvas]]; //document.getElementById('myCanvas');
      const rect = canvasElement[0].getBoundingClientRect();
      const vpt = this.myCanvas.viewportTransform;
      const zoom = this.myCanvas.getZoom();
      const left = (event.clientX - rect.left - vpt[4]) / zoom;
      const top = (event.clientY - rect.top - vpt[5]) / zoom;
      if (shapeType === 'rectangle') {
        this.addRectangle(left, top);
      } else if (shapeType === 'ellipse') {
        this.addEllipse(left, top);
      } else if (shapeType === 'rectangleFill') {
        this.addRectangleFill(left, top);
      } else if (shapeType === 'ellipseFill') {
        this.addEllipseFill(left, top);
      } else if (shapeType === 'arrow') {
        this.addArrow(left, top);
      } else if (shapeType === 'measure') {
        this.addMeasure(left, top);
      } else if (shapeType === 'simpleLine') {
        this.addSimpleLine(left, top);
      } else if (shapeType === 'zigLine') {
        this.addZigLine(left, top);
      } else if (shapeType === 'textbox') {
        this.addTextMode(left, top, event.clientX, event.clientY);
      } else {
        const data = event.dataTransfer.getData('text/plain');
        const parsedData = JSON.parse(data);
        let parentObject = null;

        this.addIcon(parsedData.name, parsedData.svg, left, top, parsedData.editable_hor, parsedData.editable_ver, parsedData.rotation, this.activeIndex)
            .then(svgGroup => {
              parentObject = svgGroup;
              if (parentObject.lockSelectability) {
                parentObject.selectable = false;
              } else {
                parentObject.selectable = !this.moveMode;
              }
              if (parsedData.attach_svg) {
                parentObject.set('attached', {
                  attach_name: parsedData.attach_name,
                  attach_svg: parsedData.attach_svg,
                  attach_editable_h: parsedData.attach_editable_h,
                  attach_editable_v: parsedData.attach_editable_v,
                  attach_rotation: parsedData.attach_rotation,
                  attach_group: parsedData.attach_group,
                });
              }
              parentObject.setCoords();
            })
            .catch(error => {
              console.error('Creating attachment error:', error);
            });

        // fetch(parsedData.src)
        //     .then(response => response.text())
        //     .then(svgText => this.addIcon(parsedData.name, svgText, left, top, parsedData.editable, this.activeIndex));

        if (parsedData.attach_svg) {
          this.addIcon(parsedData.attach_name, parsedData.attach_svg, left, top, parsedData.attach_editable_h, parsedData.attach_editable_v, parsedData.attach_rotation, parsedData.attach_group-1, false, false)
              .then(svgGroup => {
                svgGroup.set('parentId', parentObject.id);
                svgGroup.setCoords();
              })
              .catch(error => {
                console.error('Creating attachment error:', error);
              });

          // fetch(parsedData.attach_src)
          //     .then(response => response.text())
          //     .then(svgText => this.addIcon(parsedData.attach_name, svgText, left, top, parsedData.attach_editable, parsedData.attach_group-1));
        }
        this.myCanvas.requestRenderAll();
      }
      this.myCanvas.fire('object:state:changed', { target: null });
      this.isDirty = true;
    },
    selectAllObjects() {
      if(this.myCanvas) {
        const allObjects = this.myCanvas.getObjects();
        if (allObjects.length > 0) {
          const selection = new fabric.ActiveSelection(allObjects, {
            canvas: this.myCanvas,
          });
          this.myCanvas.setActiveObject(selection);
        }
      }
    },
    deselectAllObjects(render = true) {
      if (this.myCanvas) {
        this.myCanvas.discardActiveObject();
        if (render) {
          this.myCanvas.requestRenderAll();
        }
      }
    },
    // cloneObject(obj) {
    //   return fabric.util.object.clone(obj);
    // },
    clearCanvas(canvasID) {
      if (this.canvases[canvasID]) {
        this.canvases[canvasID].clear();
        this.addBoundaryFrame(this.canvases[canvasID], this.viewportWidth, this.viewportHeight);
      }
    },
    serializeCanvas(wholeProject=false) {
      if (wholeProject) {
        this.canvasJSON = saveAllCanvasesToFile(this.canvases, this.canvasButtons, this.projectName);
      } else {
        this.canvasJSON = saveCanvasToFile(this.myCanvas, this.projectName + " " + this.activeDrawingName);
      }
      this.isModalVisible = false;
    },
    deserializeCanvas() {
      const fileInput = document.getElementById('fileInput');
      fileInput.click();
    },
    loadCanvasFromFile(event) {
      const file = event.target.files[0];
      const reader = new FileReader();

      reader.onload = (e) => {
        const jsonData = JSON.parse(e.target.result);

        if (jsonData.manyCanvases) {
          this.$emit('project-name', file.name.split('.')[0]);

          this.myCanvas = null;
          this.clearAllButtons();

          this.canvases.forEach((canvas) => {
            canvas.dispose();
          });

          this.canvases.length = 0;

          const maxInnerIndex = Math.max(...Object.values(jsonData.buttons).map(button => button.innerIndex));
          if (maxInnerIndex > this.canvases.length-1) {
            for (let i = 0; i <= maxInnerIndex; i++) {
              this.addNewCanvasIfNecessary(i);
            }
            this.myCanvas = markRaw(this.canvases[0]);
            Object.entries(jsonData.buttons).forEach(([key, value]) => {
              this.addNewButtonIfNecessary(value.innerIndex, key);
            });
          }
        }
        deserializeFileToCanvas(this.myCanvas, this.canvases, jsonData);
        this.selectAllObjects();
        this.deselectAllObjects();
      };
      if(file.type === 'application/json') {
        reader.readAsText(file);
      } else {
        console.log("BAD FILE FORMAT");
      }

      event.target.value = "";
    },
    redrawCanvasWithHighQuality() {
      if(this.redrawing) return;
      this.redrawing = true;
      this.myCanvas.getObjects().forEach((obj) => {
        if (obj.type === 'image' || obj.type === 'svg' || obj.type === 'group' || obj.isType('svg')) {
          obj._originalObjectCaching = obj.objectCaching;
          obj.objectCaching = false;
          obj.dirty = true;
        }
      });

      this.myCanvas.requestRenderAll();

      setTimeout(() => {
        this.myCanvas.getObjects().forEach((obj) => {
          if (obj.type === 'image' || obj.type === 'svg' || obj.type === 'group' || obj.isType('svg')) {
            obj.objectCaching = obj._originalObjectCaching;
          }
        });
        this.redrawing = false;
      }, 10);
    },
    async generatePDF(number, projectName, toFile = true) {
      this.deselectAllObjects();

      const width = this.myCanvas.getWidth();
      const height = this.myCanvas.getHeight();
      const filteredObjects = this.myCanvas.getObjects().filter(obj => obj.name === 'noteIconInfo');
      const boundary = this.myCanvas.getObjects().find(obj => obj.name === 'boundaryFrame');

      const companyMarksGroup = await this.addCompanyMarks(projectName, this.activeDrawingName);
      const frame = this.addBoundaryFrame(null, this.viewportWidth-8, this.viewportHeight-8, 4, 4, "companyBoundary", 'black', 4);
      this.myCanvas.addObjectToLayer(companyMarksGroup, this.activeIndex, this.actualCanvas);
      this.myCanvas.addObjectToLayer(frame, this.activeIndex, this.actualCanvas);

      this.myCanvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
      this.redrawCanvasWithHighQuality();
      this.myCanvas.renderAll();

      this.myCanvas.setWidth(this.viewportWidth);
      this.myCanvas.setHeight(this.viewportHeight);
      filteredObjects.forEach(obj => {obj.visible = false;});
      if (boundary) {
        boundary.visible = false;
      }
      this.myCanvas.renderAll();

      await nextTick();
      let canvasElement = this.$refs[this.canvasesIDs[this.actualCanvas]][0];

      if (!canvasElement) {
        console.error('Canvas element not found!');
        return;
      }

      if (number === 1) {
        await generateA4PDF(canvasElement, this.myCanvas, this.viewportWidth, this.viewportHeight, projectName, toFile);
      } else if (number === 2) {
        await generateA3PDF(canvasElement, this.myCanvas, this.viewportWidth, this.viewportHeight, projectName, toFile);
      }

      this.myCanvas.setWidth(width);
      this.myCanvas.setHeight(height);
      filteredObjects.forEach(obj => {obj.visible = true;});
      if (boundary) {
        boundary.visible = true;
      }
      this.myCanvas.remove(frame);
      this.myCanvas.remove(companyMarksGroup);
      this.myCanvas.renderAll();
      this.isModalVisible = false;
    },
    async generateSVG(number, projectName) {
      this.deselectAllObjects();

      const width = this.myCanvas.getWidth();
      const height = this.myCanvas.getHeight();
      const filteredObjects = this.myCanvas.getObjects().filter(obj => obj.name === 'noteIconInfo');
      const boundary = this.myCanvas.getObjects().find(obj => obj.name === 'boundaryFrame');

      const companyMarksGroup = await this.addCompanyMarks(projectName, this.activeDrawingName);
      const frame = this.addBoundaryFrame(null, this.viewportWidth-8, this.viewportHeight-8, 4, 4, "companyBoundary", 'black', 4);
      this.myCanvas.addObjectToLayer(companyMarksGroup, this.activeIndex, this.actualCanvas);
      this.myCanvas.addObjectToLayer(frame, this.activeIndex, this.actualCanvas);

      this.myCanvas.setViewportTransform([1, 0, 0, 1, 0, 0]);

      this.myCanvas.setWidth(this.viewportWidth);
      this.myCanvas.setHeight(this.viewportHeight);
      filteredObjects.forEach(obj => {obj.visible = false;});
      if (boundary) {
        boundary.visible = false;
      }
      this.myCanvas.renderAll();

      await nextTick();
      let canvasElement = this.$refs[this.canvasesIDs[this.actualCanvas]][0];

      if (!canvasElement) {
        console.error('Canvas element not found!');
        return;
      }

      if (number === 1) {
        await generateA3SVG(this.myCanvas, projectName, true);
      } else if (number === 2) {
        await generateA3SVG(this.myCanvas, projectName, false);
      }

      this.myCanvas.setWidth(width);
      this.myCanvas.setHeight(height);
      filteredObjects.forEach(obj => {obj.visible = true;});
      if (boundary) {
        boundary.visible = true;
      }
      this.myCanvas.remove(frame);
      this.myCanvas.remove(companyMarksGroup);
      this.myCanvas.renderAll();
      this.isModalVisible = false;
    },
    async printCanvas(number) {
      await this.generatePDF(number, this.projectName, false);
      this.isModalVisible = false;
    },
    printNotes() {
      const canvas = this.myCanvas;
      generateNotesTable(canvas);
    },
    resizeCanvas() {
      if (this.myCanvas) {
        const canvasElement = this.myCanvas.getElement();
        const parent = canvasElement.parentElement.parentElement;
        this.myCanvas.setWidth(parent.clientWidth - 10);
        this.myCanvas.setHeight(parent.clientHeight - 5);
      }
    },
    // Text manipulation
    addTextMode(left, top, absX, absY) {
      // const zoom = this.myCanvas.getZoom();
      this.textDropPoint = {x: left-10, y: top-10};
      this.showTextInput = true;
      this.textInputStyle.top = `${absY-10}px`;
      this.textInputStyle.left = `${absX-10}px`;
      this.textInputStyle.display = 'block';
      this.$nextTick(() => {
        this.$refs.textInput.focus();
      });
    },
    confirmText() {
      if (this.inputText.trim() !== '') {
        if (this.currentText) {
          this.currentText.set('text', this.inputText);
          this.currentText = null;
        } else {
          const textObj = new fabric.IText(this.inputText, {
            left: parseInt(this.textDropPoint.x, 10),
            top: parseInt(this.textDropPoint.y, 10),
            fill: this.pickColor,
            fontSize: 32,
            stroke: this.pickColor,
            fontFamily: 'Open Sans, sans-serif',
            fontWeight: '300',
            fontStyle: 'italic',
            strokeWidth: 0.1,
            name: 'Text'
          });
          // textObj.set('width', textObj.width*1.2);
          textObj.setControlsVisibility({
            mt: false, // Top middle
            mb: false, // Bottom middle
            ml: false,  // Middle left
            mr: false,  // Middle right
            tl: true, // Top left
            tr: true, // Top right
            bl: true, // Bottom left
            br: true  // Bottom right
          });
          this.addShapeToActiveDrawing(textObj);
        }
        this.inputText = '';
        this.showTextInput = false;
        this.textInputStyle.display = 'none';
        this.myCanvas.requestRenderAll();
      }
    },
    // onCanvasDoubleClick(opt) {
    //   const target = opt.target;
    //   if (target && target.type === 'text') {
    //     this.showTextInput = true;
    //     this.currentText = target;
    //     this.inputText = target.text;
    //     this.textInputStyle.top = `${target.top}px`;
    //     this.textInputStyle.left = `${target.left}px`;
    //     this.textInputStyle.display = 'block';
    //     this.$nextTick(() => {
    //       this.$refs.textInput.focus();
    //     });
    //   } else {
    //     // TODO think about it (double click -> add note)
    //     // showNoteInput(target);
    //   }
    // },
    // Aligment
    handleAlignToLeft() {
      if (this.activeObjects && this.activeObjects.type === 'activeSelection') {
        alignLeft(this.activeObjects.getObjects());
        this.myCanvas.fire('object:state:changed', { target: this.activeObjects });
        this.myCanvas.requestRenderAll();
      }
    },
    handleAlignToRight() {
      if (this.activeObjects && this.activeObjects.type === 'activeSelection') {
        alignRight(this.activeObjects.getObjects());
        this.myCanvas.fire('object:state:changed', { target: this.activeObjects });
        this.myCanvas.requestRenderAll();
      }
    },
    handleAlignToTop() {
      if (this.activeObjects && this.activeObjects.type === 'activeSelection') {
        alignTop(this.activeObjects.getObjects());
        this.myCanvas.fire('object:state:changed', { target: this.activeObjects });
        this.myCanvas.requestRenderAll();
      }
    },
    handleAlignToBottom() {
      if (this.activeObjects && this.activeObjects.type === 'activeSelection') {
        alignBottom(this.activeObjects.getObjects());
        this.myCanvas.fire('object:state:changed', { target: this.activeObjects });
        this.myCanvas.requestRenderAll();
      }
    },
    handleAlignToCenterVertical() {
      if (this.activeObjects && this.activeObjects.type === 'activeSelection') {
        alignCenterVertical(this.activeObjects.getObjects());
        this.myCanvas.fire('object:state:changed', { target: this.activeObjects });
        this.myCanvas.requestRenderAll();
      }
    },
    handleAlignToCenterHorizontal() {
      if (this.activeObjects && this.activeObjects.type === 'activeSelection') {
        alignCenterHorizontal(this.activeObjects.getObjects());
        this.myCanvas.fire('object:state:changed', { target: this.activeObjects });
        this.myCanvas.requestRenderAll();
      }
    },
    handleKeyDown(event) {
      if (event.ctrlKey && event.key === 's') {
        this.serializeCanvas();
      }
    },
    // eslint-disable-next-line no-unused-vars
    handleMoving(event) {
      // const target = event.target;

      // if (target.type === 'activeSelection') {
      //   target.getObjects().forEach(obj => {
      //     obj.fire('moving', {
      //       e: event.e,
      //       target: obj,
      //       transform: event.transform
      //     });
      //   });
      // }

      this.handleNoteIcon();
    },
    handleScaling() {
      if (this.activeObjects && this.activeObjects.type === 'group' && this.activeObjects.name === 'Measure') {
        const scaleX = this.activeObjects.scaleX;
        const measurePath = this.activeObjects.item(0); //  (Path)
        const text = this.activeObjects.item(1); //  (Text)

        const newValue = measurePath.width * scaleX / (11.8119 * 1.5);
        // const px_mm = 11.8119 / 3;

        text.set({
          scaleX: 1 / scaleX,
          left: -scaleX - (1 / scaleX * 12),
          text: newValue.toFixed(1).toString()
        });

        this.myCanvas.requestRenderAll();
      } else {
        this.handleNoteIcon();
      }
    },
    handleAddNote() {
      const canvasElement = this.$refs[this.canvasesIDs[this.actualCanvas]]; //document.getElementById('myCanvas');
      const rect = canvasElement[0].getBoundingClientRect();
      const vpt = this.myCanvas.viewportTransform;
      const zoom = this.myCanvas.getZoom();
      const left = rect.left - 15 + vpt[4] + this.activeObjects.left * zoom;
      const top = rect.top - 25 + vpt[5] + this.activeObjects.top * zoom;

      showNoteInput(this, this.myCanvas, this.activeObjects, left, top);
    },
    handleNoteIcon() {  // event){
      // let activeObject = event.target;
      // updateNoteIconPosition(activeObject);
      // this.myCanvas.requestRenderAll();
    },
    handleRotateLeft() {
      if (this.activeObjects) {
        if (Array.isArray(this.activeObjects)) {
          this.activeObjects.forEach(obj => {
            obj.rotate((obj.angle - 90) % 360);
            this.updateAttachedPosition(obj);
            // if (obj.noteIcon) {
            //   noteIconPosition(obj);
            // }
          });
        } else {
          this.activeObjects.rotate((this.activeObjects.angle - 90) % 360);
          this.updateAttachedPosition(this.activeObjects);
          // if(this.activeObjects.noteIcon) {
          //   noteIconPosition(this.activeObjects);
          // }

        }

        this.myCanvas.requestRenderAll();
      }
    },
    handleRotateRight() {
      if (this.activeObjects) {
        if (Array.isArray(this.activeObjects)) {
          this.activeObjects.forEach(obj => {
            obj.rotate((obj.angle + 90) % 360);
            // if (obj.noteIcon) {
            //   noteIconPosition(obj);
            // }
          });
        } else {
          this.activeObjects.rotate((this.activeObjects.angle + 90) % 360);
          // if(this.activeObjects.noteIcon) {
          //   noteIconPosition(this.activeObjects);
          // }

        }
        this.myCanvas.requestRenderAll();
      }
    },
    handleZeroDegree() {
      if (this.activeObjects) {
        if (Array.isArray(this.activeObjects)) {
          this.activeObjects.forEach(obj => {
            obj.rotate(0);
            // if (obj.noteIcon) {
            //   noteIconPosition(obj);
            // }
          });
        } else {
          this.activeObjects.rotate(0);
          // if (this.activeObjects.noteIcon) {
          //   noteIconPosition(this.activeObjects);
          // }

        }
        this.myCanvas.requestRenderAll();
      }
    },
    handleCloseObjects() {
      if (this.activeObjects && this.activeObjects.type === 'activeSelection') {
        alignCloseObjects(this.activeObjects.getObjects());
        this.myCanvas.fire('object:state:changed', { target: this.activeObjects });
        this.myCanvas.requestRenderAll();
      }
    },
    isCanvasVisible(idx) {
      return this.actualCanvas === idx;
    }
  }
};
</script>

<template>
  <input @change="loadCanvasFromFile" type="file" id="fileInput" style="display: none;" />
  <div id="canvas-container" @dragover="onDragOver" @drop="onDrop">
    <canvas
        v-for="(canvasID, index) in canvasesIDs"
        :key="index"
        :id="canvasID"
        :ref="canvasID">
    </canvas>
    <input
        v-if="showTextInput"
        ref="textInput"
        type="text"
        v-model="inputText"
        @keydown.enter="confirmText"
        @blur="confirmText"
        :style="textInputStyle"
    />
  </div>

  <ModalComponent
      :isVisible="isModalVisible"
      :show-buttons="showButtons"
      title="Informacja"
  >
    <template v-slot:default>
      <div v-html="modalMessage"></div>
    </template>
  </ModalComponent>

</template>

<style scoped>
#canvas-container {
  display: block;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
}

canvas {
  display: block;
  width: 100%;
  height: 100%;
}

input[type="text"] {
  position: absolute;
  font-size: 20px;
  outline: none;
  background: transparent;
  font-family: inherit;
}

</style>
