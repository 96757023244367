import { createApp } from 'vue';
import App from './App.vue';
import store from './store';
import themeManager from './utils/theme_manager'
import layoutManager from './utils/layout_manager'

createApp(App)
    .use(store)
    .use(themeManager)
    .use(layoutManager)
    .mount('#app');