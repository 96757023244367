<template>
    <button ref="toggleButton" @click="toggleSelector" class="rect-button toggle-button-position">
      <img src="@/assets/icons/lines_tick.svg" alt="Grubosc linii" :class="{ 'inverted': $theme.invertIcon }"/>
      <span class="badge">{{ actualLineSize }}</span>
    </button>
    <div v-if="isSelectorVisible" :style="selectorStyle" class="selector" @mouseleave="toggleSelector">
      <div v-for="size in lineSizes" :key="size" class="line-option" @click="selectLineSize(size)">
        <span>{{ size }}</span>
        <div :style="{ borderBottom: `${size}px solid black` }" class="line-preview"></div>
      </div>
    </div>
</template>

<script>
export default {
  name: 'LineSelector',
  emits: ['line-size-selected'],
  data() {
    return {
      isSelectorVisible: false,
      lineSizes: [1, 2, 3, 4, 5],
      actualLineSize: 2,
      selectorStyle: {
        top: '0px',
        left: '0px'
      }
    };
  },
  methods: {
    toggleSelector() {
      this.isSelectorVisible = !this.isSelectorVisible;
      if (!this.isSelectorVisible) return;

      const button = this.$refs.toggleButton;
      const buttonRect = button.getBoundingClientRect();
      const selectorTop = buttonRect.bottom + window.scrollY;
      const selectorLeft = buttonRect.left + window.scrollX;

      this.selectorStyle.top = `${selectorTop}px`;
      this.selectorStyle.left = `${selectorLeft-46}px`;
    },
    selectLineSize(size) {
      this.actualLineSize = size;
      this.isSelectorVisible = false;
      this.$emit('line-size-selected', size);
    }
  }
};
</script>

<style>
@import '@/assets/themes.css';

.selector {
  position: absolute;
  margin-top: 5px;
  background-color: white;
  border: 1px solid #ccc;
  padding: 10px;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.line-option {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 5px;
}

.line-preview {
  width: 100px;
  margin-left: 10px;
}

.toggle-button-position {
  position: relative;
  display: inline-block;
  cursor: pointer;
  margin-right: 8px !important;
  margin-top: 1px;
}

.rect-button .badge {
  position: absolute;
  top: 0;
  right: 0;
  padding: 2px 5px;
  border-radius: 50%;
  background: var(--secondary-background);
  color: var(--inverse-text-color);
  font-size: 12px;
  line-height: 1;
  transform: translate(50%, -50%);
}
</style>
