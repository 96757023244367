<template>
  <div class="button-container">
    <ButtonWithMenu
        v-for="(button, index) in buttons"
        :key="index"
        :label="button.label"
        :inner-index="button.innerIndex"
        :active-class="button.activeMenu"
        :active-canvas="button.activeCanvas"
        @show-context-menu="showContextMenu(index, $event)"
        @showed-drawing-index="setDrawingIndex(index, $event)"
    />

    <button v-if="buttons.length<10" class="add-button" @click="addItem(false)">
      +
    </button>
  </div>

  <div class="context-menu" ref="contextMenu" v-show="isContextMenuVisible">
    <div class="context-menu-buttons">
      <div class="context-menu-item double-context-menu-item">
        <button class="context-menu-item smaller-item left-small-item" @click="moveItemLeft" title="Przesuń przycisk w lewo">
          <img src="@/assets/icons/btn_left.svg" alt="Przesuń przycisk w lewo" :class="{ 'inverted': $theme.invertIcon }"/>
        </button>
        <button class="context-menu-item smaller-item right-small-item" @click="moveItemRight" title="Przesuń przycisk w prawo">
          <img src="@/assets/icons/btn_right.svg" alt="Przesuń przycisk w prawo" :class="{ 'inverted': $theme.invertIcon }"/>
        </button>
      </div>
      <button class="context-menu-item" @click="renameItem" title="Zmień nazwę przycisku">
        <img src="@/assets/icons/btn_edit.svg" alt="Zmień nazwę przycisku" :class="{ 'inverted': $theme.invertIcon }"/>
      </button>
      <button class="context-menu-item" @click="addItemWithCopy" title="Utwórz kopię tego rysunku">
        <img src="@/assets/icons/btn_copy.svg" alt="Utwórz kopię tego rysunku" :class="{ 'inverted': $theme.invertIcon }"/>
      </button>
      <button class="context-menu-item" @click="deleteItem" title="Usuń rysunek">
        <img src="@/assets/icons/btn_del.svg" alt="Usuń rysunek" :class="{ 'inverted': $theme.invertIcon }"/>
      </button>
    </div>
  </div>

  <ModalComponent
      ref="modalComponent"
      :isVisible="isModalVisible"
      :isInputVisible="isInputVisible"
      :title="modalTitle"
      :inputText="inputText"
      :primary-text="modalConfirmText"
      :alternative-text="modalCancelText"
      @primary="handleConfirm"
      @alternative="handleCancel"
      @close="handleClose"
  >
    <template v-slot:default>
      <div v-html="modalMessage"></div>
    </template>
  </ModalComponent>

</template>


<script>
import ButtonWithMenu from './ButtonWithMenu.vue';
import ModalComponent from "@/components/ModalComponent.vue";
import {mapActions, mapState} from "vuex";

export default {
  components: {
    ModalComponent,
    ButtonWithMenu,
  },
  emits: ["show-drawing", "new-drawing-name"],
  props: {
    drawings: Array,
  },
  computed: {
    ...mapState(['canvasID'])
  },
  data() {
    return {
      actualBtnIndex: 0,
      isContextMenuVisible: false,
      buttons: [{ label: 'Rysunek', activeMenu: false, activeCanvas: true, innerIndex: 0 }],
      buttonsBusyIndexes: Array(10).fill(false),

      isModalVisible: false,
      isInputVisible: true,
      modalTitle: "Zmiana nazwy",
      modalMessage: "Podaj nową nazwę rysunku",
      modalConfirmText: "Zmień",
      modalCancelText: "Anuluj",
      inputText: ""

    };
  },
  mounted() {
    this.buttonsBusyIndexes[0] = true;
    this.updateCanvasButtons(this.buttons);
    this.canvasButtonsUpdated();
  },
  methods: {
    ...mapActions(['updateCanvasButtons', 'canvasButtonsUpdated']),
    setModalToInput() {
      this.isInputVisible = true;
      this.modalTitle = "Zmiana nazwy";
      this.modalMessage = "Podaj nową nazwę rysunku";
      this.modalConfirmText = "Zmień";
      this.modalCancelText = "Anuluj";
    },
    setModalToConfirm() {
      this.isInputVisible = false;
      this.modalTitle = "Usuwanie";
      this.modalMessage = "Czy na pewno chcesz usunąć ten rysunek?";
      this.modalConfirmText = "Usuń";
      this.modalCancelText = "Anuluj";
    },
    handleConfirm() {
      if (this.isInputVisible) {
        this.buttons[this.actualBtnIndex].label = this.$refs.modalComponent.getInputValue();
        this.$emit('new-drawing-name', this.buttons[this.actualBtnIndex].label);
        this.resetButtonsActive();
        this.isModalVisible = false;
      } else {
        this.$store.dispatch('clearCanvas', this.buttons[this.actualBtnIndex].innerIndex);
        this.buttonsBusyIndexes[this.buttons[this.actualBtnIndex].innerIndex] = false;
        this.buttons.splice(this.actualBtnIndex, 1);
        this.resetButtonsActive();
        this.isModalVisible = false;
        if (this.buttons.length === 0) {
          this.addItem();
        }
        this.selectFirstButton();
      }
    },
    handleCancel() {
      this.resetButtonsActive();
      this.isModalVisible = false;
    },
    handleClose() {
      this.resetButtonsActive();
      this.isModalVisible = false;
    },
    resetButtonsActive() {
      for (let i = 0; i < this.buttons.length; i++) {
        this.buttons[i].activeMenu = false;
      }
    },
    resetCanvasActive() {
      for (let i = 0; i < this.buttons.length; i++) {
        this.buttons[i].activeCanvas = false;
      }
    },
    getLeftPosition(left) {
      return parseFloat(left);
    },
    setNewCanvasIndexToAll(index, label, deepCopy=false){
      this.$emit('show-drawing', index, label, deepCopy);
      this.$store.dispatch('setCanvasIndex', index);
    },
    showContextMenu(btnIndex, event) {
      const newX = this.getLeftPosition(event);
      this.$refs.contextMenu.style.left = (newX - 350) + 'px';

      if (this.actualBtnIndex === btnIndex) {
        this.isContextMenuVisible = !this.isContextMenuVisible;
      } else if (!this.isContextMenuVisible) {
        this.isContextMenuVisible = true;
      }

      if (this.isContextMenuVisible) {
        this.resetButtonsActive();
        this.resetCanvasActive();
        this.actualBtnIndex = btnIndex;
        this.buttons[btnIndex].activeMenu = true;
        this.buttons[btnIndex].activeCanvas = true;
        this.setNewCanvasIndexToAll(this.buttons[btnIndex].innerIndex, this.buttons[btnIndex].label);
      }
    },
    addItem(deepCopy=false, index=-1, label='') {
      const newBusyIndex = index===-1? this.buttonsBusyIndexes.findIndex(element => element === false) : index;
      const btnLabel = label? label : `Rysunek ${this.buttons.length + 1}`;
      this.buttons.push({ label: btnLabel,
                          activeMenu: false,
                          activeCanvas: false,
                          innerIndex: newBusyIndex });
      this.buttonsBusyIndexes[newBusyIndex] = true;

      this.setDrawingIndex(this.buttons.length-1, newBusyIndex, deepCopy);
      this.updateCanvasButtons(this.buttons);
      this.canvasButtonsUpdated();
    },
    addItemWithCopy() {
      this.addItem(true);
    },
    resetAllButtons() {
      this.buttons.length = 0;
      this.buttonsBusyIndexes = Array(10).fill(false);
      this.buttons = [{ label: 'Rysunek', activeMenu: false, activeCanvas: true, innerIndex: 0 }];
      this.buttonsBusyIndexes[0] = true;
    },
    addNewButton(canvasIndex, buttonName) {
      const index = this.buttons.findIndex(button => button.innerIndex === canvasIndex);
      if (index !== -1) {
        this.buttons.splice(index, 1);
      }
      this.addItem(false, canvasIndex, buttonName);
    },
    renameItem() {
      this.isContextMenuVisible = !this.isContextMenuVisible;
      this.inputText = this.buttons[this.actualBtnIndex].label;
      this.isModalVisible = true;
      this.setModalToInput();
    },
    moveItemLeft() {
      this.isContextMenuVisible = !this.isContextMenuVisible;
      if (this.actualBtnIndex > 0) {
        const button = this.buttons.splice(this.actualBtnIndex, 1)[0];
        this.buttons.splice(this.actualBtnIndex - 1, 0, button);
      }
      this.resetButtonsActive();
    },
    moveItemRight() {
      this.isContextMenuVisible = !this.isContextMenuVisible;
      if (this.actualBtnIndex < this.buttons.length - 1) {
        const button = this.buttons.splice(this.actualBtnIndex, 1)[0];
        this.buttons.splice(this.actualBtnIndex + 1, 0, button);
      }
      this.resetButtonsActive();
    },
    deleteItem() {
      this.isContextMenuVisible = !this.isContextMenuVisible;
      this.isModalVisible = true;
      this.setModalToConfirm();
    },
    setDrawingIndex(btnIndex, cnvIndex, deepCopy=false) {
      this.isContextMenuVisible = false;
      this.resetCanvasActive();
      this.resetButtonsActive();
      this.buttons[btnIndex].activeCanvas = true;
      this.setNewCanvasIndexToAll(cnvIndex, this.buttons[btnIndex].label, deepCopy);
    },
    selectFirstButton() {
      this.setDrawingIndex(0, this.buttons[0].innerIndex);
    },
  },
};
</script>

<style scoped>
.button-container {
  display: flex;
  min-width: 220px;
  max-width: calc(100vw - 420px);
  gap: 1rem;
  overflow-x: auto;
  align-items: center;
  min-height: 99%;
  padding: 0;
  box-sizing: border-box;
}

.button-container::-webkit-scrollbar {
  height: 6px;
}

.button-container::-webkit-scrollbar-thumb {
  background-color: var(--buttons-color);
  border-radius: var(--border-radius);
  cursor: pointer;
}

.button-container::-webkit-scrollbar-track {
  background: var(--primary-background);
  border-radius: var(--border-radius);
}

.add-button {
  width: 40px;
  height: 40px;
  min-width: 40px;
  font-size: 24px;
  font-weight: bold;
  border-radius: var(--big-buttons-border-radius);
  color: var(--primary-text-color);
  background-color: var(--tab-buttons-color);
  border: 1px solid var(--buttons-border-color);
  cursor: pointer;
}

.add-button:hover {
  background-color: var(--buttons-hover-color);
}

.context-menu {
  position: absolute;
  top: 2px;
  left: 0;
  height: 70px;
  background-color: var(--component-background);
  border: 1px solid var(--component-border-color);
  border-radius: var(--border-radius);
  color: var(--primary-text-color);
  padding: 2px 4px;
  z-index: 99999;
  min-width: 90px;
}

.context-menu-buttons {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0.3rem;
}

.double-context-menu-item {
  padding: 0 !important;
  border: none !important;
}

.smaller-item {
  width: 30px !important;
}

.left-small-item {
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.right-small-item {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.context-menu-item {
  width: 60px;
  padding:2px;
  border-radius: var(--big-buttons-border-radius);
  color: var(--primary-text-color);
  background-color: var(--tab-buttons-color);
  border: 1px solid var(--buttons-border-color);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.context-menu-item:hover {
  background-color: var(--buttons-hover-color)
}

</style>
