// layout_manager.js

import { ref, computed } from 'vue'

const layout = ref(localStorage.getItem('menu') || 'default')

const layoutManager = {
    install(app) {
        const devMenu = computed(() => layout.value === 'dev')
        const menuSize = computed(() => devMenu.value ? 3 : 1)

        app.config.globalProperties.$layout = {
            get current() { return layout.value },
            set current(value) { layout.value = value },
            get isDevMenu() { return devMenu.value },
            get menuSize() { return menuSize },
            toggleMenu() {
                this.current = this.isDevMenu ? 'default' : 'dev'
                document.documentElement.setAttribute('data-menu', this.current)
                localStorage.setItem('menu', this.current)
            }
        }

        document.documentElement.setAttribute('data-menu', layout.value)
    }
}

export default layoutManager