<template>
  <button @click="handleSendSelectedToBack" :disabled="disable_buttons" title="Przenieś na spód" class="rect-button man-button-position">
    <img src="@/assets/icons/to_back.svg" alt="Przenieś na spód" />  <!-- :class="{ 'inverted': $theme.invertIcon }"/> -->
  </button>
  <button @click="handleBringSelectedToFront" :disabled="disable_buttons" title="Przenieś na wierzch" class="rect-button man-button-position">
    <img src="@/assets/icons/to_front.svg" alt="Przenieś na wierzch"/> <!-- :class="{ 'inverted': $theme.invertIcon }"/> -->
  </button>
  <button @click="handleGroupSelected" :disabled="groups_enabled" title="Grupuj" class="rect-button man-button-position" style="margin-left: 8px;">
    <img src="@/assets/icons/group.svg" alt="Grupuj" :class="{ 'inverted': $theme.invertIcon }"/>
  </button>
  <button @click="handleUngroupSelected" :disabled="groups_enabled" title="Rozgrupuj" class="rect-button man-button-position">
    <img src="@/assets/icons/ungroup.svg" alt="Rozgrupuj" :class="{ 'inverted': $theme.invertIcon }"/>
  </button>
  <button @click="handleCutSelected" :disabled="disable_buttons" title="Wytnij" class="rect-button man-button-position" style="margin-left: 8px;">
    <img src="@/assets/icons/cut.svg" alt="Wytnij" :class="{ 'inverted': $theme.invertIcon }"/>
  </button>
  <button @click="handleCopySelected" :disabled="disable_buttons" title="Kopiuj" class="rect-button man-button-position">
    <img src="@/assets/icons/copy.svg" alt="Kopiuj" :class="{ 'inverted': $theme.invertIcon }"/>
  </button>
  <button @click="handlePasteSelected" :disabled="!paste_posible" title="Wklej" class="rect-button man-button-position">
    <img src="@/assets/icons/paste.svg" alt="Wklej" :class="{ 'inverted': $theme.invertIcon }"/>
  </button>
  <button @click="handleDeleteSelected" :disabled="disable_buttons" title="Usuń" class="rect-button man-button-position">
    <img src="@/assets/icons/delete.svg" alt="Usuń" :class="{ 'inverted': $theme.invertIcon }"/>
  </button>
</template>

<script>

import {addIcon} from "@/utils/add_icon";
import {v4 as uv4} from "uuid";
import {mapState} from "vuex";
import {markRaw} from "vue";

export default {
  name: 'ObjectsManipulation',
  props: {
    canvas: null,
    selectedObjects: null,

    disable_buttons: Boolean,
    groups_enabled: Boolean,
  },
  watch: {
    canvas: {
      immediate: true,
      handler(newCanvas) {
        if (newCanvas) {
          this.myCanvas = newCanvas;
        }
      },
    },
    selectedObjects: {
      immediate: true,
      handler(newBag) {
        if (newBag) {
          this.activeObjects = newBag;
        }
      },
    },
  },
  data() {
    return {
      clipboard: null,
      activeObjects: null,
      myCanvas: null,
      canvasIndex: 0,
      cloneXposition: 0,
    };
  },
  computed: {
    ...mapState(['canvasID']),
    paste_posible() {
      return !!this.clipboard;
    }
  },
  mounted() {
    window.addEventListener('keydown', this.handleKeyDown);
  },
  methods: {
    clearClipboard() {
      this.clipboard = null;
    },
    handleKeyDown(event) {
      if (event.ctrlKey && event.key === 'x') {
        this.handleCutSelected();
      }
      if (event.ctrlKey && event.key === 'c') {
        this.handleCopySelected();
      }
      if (event.ctrlKey && event.key === 'v') {
        this.handlePasteSelected();
      }
      if (event.key === 'Delete' && this.activeObjects) {
        this.handleDeleteSelected();
      }
    },
    handleCutSelected() {
      if (this.activeObjects && !(Array.isArray(this.activeObjects) && this.activeObjects.length === 0)) {
        this.activeObjects.clone(cloned => {
          this.clipboard = markRaw(cloned);
          this.cloneXposition = cloned.left + cloned.width;
          this.canvasIndex = this.canvasID;

          // Copy _controlsVisibility for each object in active selection
          if (this.clipboard.type === 'activeSelection') {
            const activeObjects = this.activeObjects.getObjects();
            this.clipboard.forEachObject((obj, index) => {
              obj._controlsVisibility = { ...activeObjects[index]._controlsVisibility };

              obj.activeIndex = activeObjects[index].activeIndex;
              obj.drawingIndex = activeObjects[index].drawingIndex;
              obj.name = activeObjects[index].name;

              if (activeObjects[index].id) {
                obj.id = activeObjects[index].id;
              }
              if (activeObjects[index].attached) {
                obj.attached = { ...activeObjects[index].attached };
              }
            });
            activeObjects.filter(obj => obj.name !== 'handler').forEach(obj => {
              this.removeObjectWithSiblings(obj);
            });
          } else if(this.activeObjects.name !== 'handler') {
            this.clipboard._controlsVisibility = { ...this.activeObjects._controlsVisibility };

            this.clipboard.activeIndex = this.activeObjects.activeIndex;
            this.clipboard.drawingIndex = this.activeObjects.drawingIndex;
            this.clipboard.name = this.activeObjects.name;

            if (this.activeObjects.id) {
              this.clipboard.id = this.activeObjects.id;
            }
            if (this.activeObjects.attached) {
              this.clipboard.attached = { ...this.activeObjects.attached };
            }

            this.removeObjectWithSiblings(this.activeObjects);
          }

          this.myCanvas.discardActiveObject();
          this.myCanvas.requestRenderAll();
        });
      }
    },
    handleCopySelected() {
      if (this.activeObjects && !(Array.isArray(this.activeObjects) && this.activeObjects.length === 0)) {
        this.activeObjects.clone(cloned => {
          this.clipboard = cloned;
          this.cloneXposition = cloned.left + cloned.width;
          this.canvasIndex = this.canvasID;

          // Copy _controlsVisibility for each object in active selection
          if (this.clipboard.type === 'activeSelection') {
            const activeObjects = this.activeObjects.getObjects();
            this.clipboard.forEachObject((obj, index) => {
              obj._controlsVisibility = { ...activeObjects[index]._controlsVisibility };

              obj.activeIndex = activeObjects[index].activeIndex;
              obj.drawingIndex = activeObjects[index].drawingIndex;
              obj.name = activeObjects[index].name;

              if (activeObjects[index].id) {
                obj.id = activeObjects[index].id;
              }
              if (activeObjects[index].attached) {
                obj.attached = { ...activeObjects[index].attached };
              }
            });
          } else {
            this.clipboard._controlsVisibility = { ...this.activeObjects._controlsVisibility };

            this.clipboard.activeIndex = this.activeObjects.activeIndex;
            this.clipboard.drawingIndex = this.activeObjects.drawingIndex;
            this.clipboard.name = this.activeObjects.name;

            if (this.activeObjects.id) {
              this.clipboard.id = this.activeObjects.id;
            }
            if (this.activeObjects.attached) {
              this.clipboard.attached = { ...this.activeObjects.attached };
            }
          }
          console.log('Object copied to clipboard');
        });
      }
    },
    async pasteObjectWithSiblings(object, object_id, activeIndex, drawingIndex, ctrlVisibility, attached, name) {
      if (name === 'handler') {
        return;
      }
      object.activeIndex = activeIndex;
      object.drawingIndex = drawingIndex;
      object._controlsVisibility = ctrlVisibility;
      object.name = name;
      if (object_id) {
        object.id = uv4();
      }
      if (attached) {
        object.attached = attached;
      }
      console.log("DRAWING ID: ", drawingIndex);
      this.myCanvas.addObjectToLayer(object, activeIndex, drawingIndex);
      if (attached) {
        return addIcon(this.myCanvas, attached.attach_name, attached.attach_svg, object.left, object.top, attached.attach_editable_v, attached.attach_editable_h, attached.attach_rotation, attached.attach_group-1, drawingIndex, false, false)
            .then(svgGroup => {
              svgGroup.set('parentId', object.id);
            })
            .catch(error => {
              console.error('Creating attachment error:', error);
            });
      }
      object.setCoords();
    },
    handlePasteSelected() {
      if (this.clipboard) {
        this.clipboard.clone(clonedObj => {
          this.myCanvas.discardActiveObject();
          const originalLeft = clonedObj.left;
          clonedObj.set({
            left: this.cloneXposition,
            top: this.clipboard.top,
            evented: true,
          });

          this.cloneXposition += clonedObj.width;

          if (this.canvasID !== this.canvasIndex) {
            clonedObj.set({
              left: originalLeft
            });
            this.cloneXposition = clonedObj.left + clonedObj.width;
            this.canvasIndex = this.canvasID;
          }
          console.log("MAIN INDEX: ", this.canvasIndex);

          if (clonedObj.type === 'activeSelection') {
            clonedObj.canvas = this.myCanvas;

            clonedObj.forEachObject((obj, index) => {
              const activeIndex = this.clipboard._objects[index].activeIndex;
              const ctrlVisibility = {...this.clipboard._objects[index]._controlsVisibility };
              const attached = this.clipboard._objects[index].attached? {...this.clipboard._objects[index].attached } : null;
              const obj_id = this.clipboard._objects[index].id? this.clipboard._objects[index].id : null;
              const name = this.clipboard._objects[index].name;

              this.pasteObjectWithSiblings(obj, obj_id, activeIndex, this.canvasIndex, ctrlVisibility, attached, name); //
            });
          } else {
            const ctrlVisibility = {...this.clipboard._controlsVisibility};
            const attached = this.clipboard.attached ? {...this.clipboard.attached} : null;

            this.pasteObjectWithSiblings(clonedObj, this.clipboard.id, this.clipboard.activeIndex, this.canvasIndex, ctrlVisibility, attached, this.clipboard.name);
          }

          if (this.clipboard.name !== 'handler') {
            this.myCanvas.setActiveObject(clonedObj);
            clonedObj.setCoords();
            this.myCanvas.requestRenderAll();

            this.myCanvas.fire('object:state:changed', {target: clonedObj});
          }
          clonedObj.setCoords();
        });
      }
    },
    removeObjectWithSiblings(object) {
      if (object.attached) {
        const childObject = this.myCanvas.getObjects().find(o => o.parentId === object.id);
        if (childObject) {
          this.myCanvas.remove(childObject);
        }
      }
      if (object.parentId) {
        const value = object.parentId
        const parentObject = this.myCanvas.getObjects().find(o => o.id === value);
        if(parentObject) {
          this.myCanvas.remove(parentObject);
        }
        const childObjects = this.myCanvas.getObjects().filter(o => o.parentId === value);
        if(childObjects) {
          childObjects.forEach(obj => {
            this.myCanvas.remove(obj);
          });
        }
      }
      this.myCanvas.remove(object);
      this.myCanvas.discardActiveObject();
      this.myCanvas.requestRenderAll();
    },
    handleDeleteSelected() {
      let activeObjects = [];
      if (this.activeObjects && this.activeObjects.type === 'activeSelection') {
        activeObjects = this.activeObjects.getObjects();
        activeObjects.forEach(obj => {
          this.removeObjectWithSiblings(obj)
        });
      } else {
        this.removeObjectWithSiblings(this.activeObjects);
      }
      this.myCanvas.requestRenderAll();
    },
    // Manipulation
    handleSendSelectedToBack() {
      if (this.activeObjects) {
        this.activeObjects.sendToBack();
        this.myCanvas.fire('object:state:changed', { target: this.activeObjects });
        this.myCanvas.requestRenderAll();
      }
    },
    handleBringSelectedToFront() {
      if (this.activeObjects) {
        this.myCanvas.bringToFront(this.activeObjects);
        this.myCanvas.sortObjectsByZIndex();
        this.myCanvas.fire('object:state:changed', { target: this.activeObjects });
        this.myCanvas.requestRenderAll();
      }
    },
    handleGroupSelected() {
      if (this.activeObjects && this.activeObjects.type === 'activeSelection') {
        let activeGroup = this.activeObjects.toGroup();
        activeGroup.setControlsVisibility({
          mt: false, // Top middle
          mb: false, // Bottom middle
          ml: false,  // Middle left
          mr: false,  // Middle right
          tl: false, // Top left
          tr: false, // Top right
          bl: false, // Bottom left
          br: false,  // Bottom right
          mtr: false
        });
        this.myCanvas.fire('object:state:changed', { target: activeGroup });
        this.myCanvas.discardActiveObject();
        this.myCanvas.setActiveObject(activeGroup);
        this.myCanvas.requestRenderAll();
      }
    },
    handleUngroupSelected() {
      if (this.activeObjects && this.activeObjects.type === 'group' && !this.activeObjects.isFromBase) {
        this.activeObjects.toActiveSelection();
        this.myCanvas.discardActiveObject();
        this.myCanvas.fire('object:state:changed', { target: null });
        this.myCanvas.requestRenderAll();
      } else {
        console.log('Active object is not a group');
      }
    },
  }
};
</script>

<style scoped>
@import '@/assets/themes.css';

.manipulation-buttons button img {
  width: 24px;
  height: 24px;
}
</style>
