// import { addNoteIconToObject, removeNoteIconFromObject } from '@/utils/notes'
import { saveAs } from 'file-saver';

export function saveAllCanvasesToFile(canvases, buttons, filename = 'projekt.json') {
    const serializedData = serializeCanvases(canvases, buttons);
    const blob = new Blob([serializedData], { type: 'application/json' });
    saveAs(blob, filename);
    return serializedData;
}

export function saveCanvasToFile(canvas, filename = 'rysunek.json') {
    const serializedData = JSON.stringify(saveCanvas(canvas));
    const blob = new Blob([serializedData], { type: 'application/json' });
    saveAs(blob, filename);
    return serializedData;
}

export function saveCanvas(canvas) {
    const originalData = canvas.toJSON(['lockScalingX', 'lockScalingY', 'lockRotation','pathOffset',
        'selectable', 'evented', 'hasControls', 'hasBorders', 'note', 'activeIndex', 'drawingIndex',
        'id', 'parentId', 'name', 'controlPoints', 'objectCaching', 'attached', 'lockSelectability',
        'isFromBase', '_controlsVisibility']);
    // Filtruj obiekty z name = 'noteIconInfo'
    originalData.objects = originalData.objects.filter(obj => obj.name !== 'noteIconInfo');

    return originalData;
}

export function serializeCanvases(canvases, buttonsObjs) {
    const buttonsObjByLabel = buttonsObjs.reduce((acc, button) => {
        acc[button.label] = button;
        return acc;
    }, {});

    const allCanvasData = {
        manyCanvases: true,
        buttons: buttonsObjByLabel,
        canvases: {}
    };

    buttonsObjs.forEach((button) => {
        allCanvasData.canvases[button.label] = saveCanvas(canvases[button.innerIndex]);
    });

    return JSON.stringify(allCanvasData);
}

export function deserializeFileToCanvas(actualCanvas, canvases, jsonData) {
    if (jsonData.manyCanvases) {
        console.log("Plik projektu");

        const buttonsData = jsonData.buttons;

        Object.keys(jsonData.canvases).forEach((canvasName) => {
            const canvasData = jsonData.canvases[canvasName];

            deserializeCanvas(canvases[buttonsData[canvasName].innerIndex], canvasData);
        });
    } else {
        console.log("Plik pojedynczego rysunku");
        deserializeCanvas(actualCanvas, jsonData);
    }
}

export function deserializeCanvas(canvas, canvasJSON) {
    // canvas.clear();
    const pathsToUpdate = [];
    const objectsWithNote = [];

    canvas.loadFromJSON(canvasJSON, () => {
        // Wszystkie obiekty zostały załadowane
        objectsWithNote.forEach(object => {
            console.log(object);
            const note = object.note;
            console.log(note);
            // if (note) {
            //     addNoteIconToObject(canvas, object);
            // } else {
            //     removeNoteIconFromObject(canvas, object);
            // }
            canvas.renderAll();
        });
        pathsToUpdate.forEach(path => {
            if (path.type === 'path' && path.name === 'BezierCurve' && 'controlPoints' in path) {
                const controlPoints = path.controlPoints.map(id => canvas.getObjects().find(o => o.id === id));

                const updatePath = () => {
                    const newPathData = [
                        ['M', controlPoints[0].left, controlPoints[0].top],
                        ['Q', controlPoints[1].left, controlPoints[1].top, controlPoints[2].left, controlPoints[2].top]
                    ];
                    path.set({ path: newPathData });
                    path.set({
                        scaleX: 1,
                        scaleY: 1
                    });

                    path.setCoords();
                    canvas.renderAll();
                };

                controlPoints.forEach(point => {
                    point.on('moving', updatePath);
                    point.on('deselected', updatePath);
                });

                updatePath();
            }
        });

        canvas.renderAll();
    }, (o, object) => {
        if (object.type !== 'activeSelection') {
            object.set({
                lockScalingX: o.lockScalingX,
                lockScalingY: o.lockScalingY,
                lockRotation: o.lockRotation,
                controlsVisibility: o.controlsVisibility
            });
        }
        if ('note' in object) {
            objectsWithNote.push(object);
        }
        if (object.type === 'path' && object.name === 'BezierCurve' && 'controlPoints' in object) {
            object.set({pathOffset: o.pathOffset});
            object.set({width: o.width, height: o.height});
            pathsToUpdate.push(object);
        }
    });
}

