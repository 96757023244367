<template>
  <ZoomComponent
      v-if="canvasHook"
      :canvas="canvasHook"
      :viewportWidth="viewportWidth"
      :viewportHeight="viewportHeight"
  />
  <HandButton
      @update:moveActive="handleMoveActive"
  />
  <ToggleButton
      :is-open="isGlobalSelectOpen"
      :is-disable="padlockButtonDisabled"
      @update:isOpen="changeSelectableForObjects"
  />
  <ThemeSwitcher></ThemeSwitcher>
  <HelpButton></HelpButton>

  <MenuSwitcher></MenuSwitcher>
</template>

<script>
import ToggleButton from "@/components/OpenCloseButton.vue";
import ZoomComponent from "@/components/ZoomComponent.vue";
import HandButton from "@/components/HandButton.vue";
import ThemeSwitcher from "@/components/ThemeSwitcher.vue";
import MenuSwitcher from "@/components/MenuSwitcher.vue";
import HelpButton from "@/components/HelpModule.vue";

export default {
  components: {
    HelpButton,
    MenuSwitcher,
    ThemeSwitcher,
    HandButton,
    ToggleButton,
    ZoomComponent
  },
  data() {
    return {
      canvasHook: null
    }
  },
  props: {
    myCanvas: null,
    viewportWidth: Number,
    viewportHeight: Number,
    isGlobalSelectOpen: Boolean,
    padlockButtonDisabled: Boolean,
  },
  emits: [
    'update-message',
    'move-active',
    'change-selectable-for-objects',
  ],
  watch: {
    myCanvas: {
      immediate: true,
      handler(newCanvas) {
        if (newCanvas) {
          this.canvasHook = newCanvas;
        }
      },
    },
  },
  mounted() {
    window.addEventListener('keydown', this.handleKeyDown);
  },
  methods: {
    handleKeyDown(event) {
      if (event.altKey && event.key === 'b' && !this.padlockButtonDisabled) {
        this.changeSelectableForObjects(!this.isGlobalSelectOpen);
      }
    },
    handleMoveActive(value) {
      this.$emit('move-active', value);
    },
    changeSelectableForObjects(value) {
      this.$emit('change-selectable-for-objects', value);
    },
  }
}
</script>

<style scoped>

</style>
