<template>
  <button @click="undo" :disabled="!canUndo" title="Cofnij" class="rect-button his-button-position">
    <img src="@/assets/icons/undo.svg" alt="Cofnij" :class="{ 'inverted': $theme.invertIcon }"/>
  </button>
  <button @click="redo" :disabled="!canRedo" title="Przywróć" class="rect-button his-button-position" style="margin-right: 8px;">
    <img src="@/assets/icons/redo.svg" alt="Przywróć" :class="{ 'inverted': $theme.invertIcon }"/>
  </button>
</template>

<script>
import { saveCanvas, deserializeCanvas } from '@/utils/canvas_serialization';

export default {
  name: 'HistoryComponent',
  props: {
    canvas: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      items_stack: [],
      undo_position: 0,
      inner_action: false,
      interactionOccurred: false,
      MAX_UNDO_STEPS: 10,
    };
  },
  computed: {
    canUndo() {
      return this.undo_position > 0;
    },
    canRedo() {
      return this.undo_position < this.items_stack.length - 1;
    },
  },
  watch: {
    canvas: {
      immediate: true,
      handler(newCanvas) {
        if (newCanvas) {
          const saveState = this.saveState;
          this.items_stack = [];
          // newCanvas.on('object:added', (e) => saveState(e.target));   /////// WYKORZYSTAJ ADD() DO ZROBIENIA Z-INDEX
          // newCanvas.on('object:removed', (e) => saveState(e.target));
          newCanvas.on('object:mouse:up:check', this.handleMouseUp);
          newCanvas.on('object:mouse:down:check', this.handleMouseDown);
          newCanvas.on('object:state:changed', (e) => this.saveState(e.target)); // my event

          saveState(null);
        }
      },
    },
  },
  methods: {
    handleMouseDown(event) {
      const target = event.target;
      this.interactionOccurred = !Array.isArray(target);
    },
    handleMouseUp(event) {
      if (this.interactionOccurred) {
        this.saveState(event.target);
      }
      this.interactionOccurred = false;
    },
    // stack methods
    addItem(item) {
      if (this.items_stack.length >= this.MAX_UNDO_STEPS) {
        this.removeFirstAdded(); // delete oldest if needed
      }
      this.items_stack.push(item);
    },
    removeFirstAdded() {
      this.items_stack.shift();
    },
    removeMultipleFromFront() {
      this.items_stack.splice(this.undo_position + 1, this.items_stack.length - this.undo_position - 1); // delete when undo and then other action then redo
    },
    saveState(item) {
      if (item) {
        if (item.name === 'handler' && !this.interactionOccurred || item.name === 'noteIconInfo') return;
      }
      if (this.canvas && !this.inner_action) {

        if (this.undo_position < this.items_stack.length - 1) {
          this.removeMultipleFromFront();
        }

        const jsonData = saveCanvas(this.canvas)
        this.addItem(jsonData);

        this.undo_position = this.items_stack.length - 1;
      }
    },
    undo() {
      if (this.canUndo) {
        this.inner_action = true;
        this.undo_position -= 1;
        const previousState = this.items_stack[this.undo_position];
        this.canvas.clear();
        deserializeCanvas(this.canvas, previousState);
        this.inner_action = false;
      }
    },
    redo() {
      if (this.canRedo) {
        this.inner_action = true;
        this.undo_position += 1;
        const nextState = this.items_stack[this.undo_position];
        deserializeCanvas(this.canvas, nextState);
        this.inner_action = false;
      }
    },
  },
};
</script>

<style>
@import '@/assets/themes.css';

</style>
