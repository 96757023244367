<template>
  <div class="modal" v-if="isVisible">
    <div class="modal-content">
      <span v-if="showButtons" class="modal-close-button" @click="closeWindow">&times;</span>
      <h3>{{ title }}</h3>

      <p><slot></slot></p>
      <input v-if="isInputVisible" class="modal-input" type="text" maxlength="24" v-model="inputValue">
      <br>
      <button v-if="showButtons" class="modal-button" @click="confirmPrimary">{{ primaryText }}
        <img v-if="!primaryText" alt="1 strona" src="@/assets/icons/two_pages.svg" :class="{ 'inverted': $theme.invertIcon }">
      </button>
      <button v-if="showButtons" class="modal-button" @click="confirmAlternative">{{ alternativeText }}
        <img v-if="!alternativeText" alt="1 strona" src="@/assets/icons/one_page.svg" :class="{ 'inverted': $theme.invertIcon }">
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    message: String,
    isVisible: Boolean,
    inputText: String,
    showButtons: {
      type: Boolean,
      default: true,
    },
    isInputVisible: {
      type: Boolean,
      default: false,
    },
    primaryText: {
      type: String,
      default: 'Kontynuuj'
    },
    alternativeText: {
      type: String,
      default: 'Anuluj'
    }
  },
  watch: {
    inputText: {
      handler: function (val) {
        this.inputValue = val;
      }
    }
  },
  data() {
    return {
      inputValue: '',
    };
  },
  methods: {
    confirmPrimary() {
      this.$emit('primary');
    },
    confirmAlternative() {
      this.$emit('alternative');
    },
    closeWindow() {
      this.$emit('close');
    },
    getInputValue() {
      return this.inputValue;
    }
  }
};
</script>


<style>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99999;
}

.modal-content {
  padding: 20px;
  border-radius: var(--border-radius);
  border: 5px solid var(--contrast-color);
  background: var(--component-background);
  color: var(--primary-text-color);
  box-shadow: 0 2px 8px var(--box-shadow);
  text-align: center;
}

.modal-button, input {
  background-color: var(--tab-buttons-color);
  border: 1px solid var(--buttons-border-color);
  color: var(--primary-text-color);
  padding: 10px 8px;
  margin: 8px;
  border-radius: var(--buttons-border-radius);
  cursor: pointer;
  font-size: 16px;
  width: 100px; /* Ustalony rozmiar przycisku */
}

input {
  width: 140px;
  cursor: text;
}

.modal-button:hover {
  background-color: var(--buttons-hover-color);
}

.modal-close-button {
  float: right;
  cursor: pointer;
  font-size: 26px;
}
</style>
