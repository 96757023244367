<script>
export default {
  methods: {
    toggleTheme() {
      this.$theme.toggleTheme();
    }
  },
  mounted() {
    console.log('Current theme:', this.$theme.current)
  }
}
</script>

<template>
  <div class="parent-theme">
    <button
        class="rect-button"
        :class="{ 'active': $theme.isDarkTheme }"
        @click="toggleTheme"
        title="Zmień kolorystykę"
    >
      <img src="@/assets/icons/theme_switch.svg" alt="Zmień kolorystykę" :class="{ 'inverted': $theme.invertIcon }"/>
    </button>
  </div>
</template>

<style scoped>
@import '@/assets/themes.css';

.parent-theme {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 50px;
  padding: 4px;
}

</style>