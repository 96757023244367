// import { fabric } from 'fabric';

export function showNoteInput(context, canvas, target, left, top) {

    let existingTextarea = document.getElementById('note-textarea');
    if (existingTextarea) {
        existingTextarea.remove();
    }

    let textarea = document.createElement('textarea');
    textarea.id = 'note-textarea';
    textarea.style.position = 'absolute';
    textarea.style.left = `${left}px`;
    textarea.style.top = `${top}px`;
    textarea.value = target.note || '';

    textarea.addEventListener('blur', function() {
        saveNoteToObject(canvas, target, textarea.value);
        textarea.remove();
        context.$emit('update-message', textarea.value);
    });

    document.body.appendChild(textarea);
    textarea.focus();
}

// export function updateNoteIconPosition(target) {
//     if (target.noteIcon) {
//         noteIconPosition(target);
//     }
// }
//
// export function noteIconPosition(target) {
//     const iconOffset = 10;
//     const radians = fabric.util.degreesToRadians(target.angle);
//     const cos = Math.cos(radians);
//     const sin = Math.sin(radians);
//
//     const newLeft = target.left + (target.width * target.scaleX - iconOffset) * cos - (-iconOffset) * sin;
//     const newTop = target.top + (target.width * target.scaleX - iconOffset) * sin + (-iconOffset) * cos;
//
//     target.noteIcon.set({
//         left: newLeft,
//         top: newTop,
//         angle: target.angle
//     });
//     target.noteIcon.setCoords();
// }

function saveNoteToObject(canvas, target, note) {
    target.note = note;
    // if (note) {
    //     addNoteIconToObject(canvas, target);
    // } else {
    //     removeNoteIconFromObject(canvas, target);
    // }
}

// export function addNoteIconToObject(canvas, target) {
//     if (!target.noteIcon) {
//         import('../assets/icons/note_info.svg').then((module) => {
//             fabric.Image.fromURL(module.default, function(img) {
//                 img.left = target.left + target.width - 10;
//                 img.top = target.top - 10;
//                 img.color = 'green';
//                 img.selectable = false;
//                 img.evented = false;
//                 img.name = 'noteIconInfo';
//                 target.noteIcon = img;
//                 canvas.add(img);
//                 noteIconPosition(target);
//             });
//         });
//     }
// }
//
// export function removeNoteIconFromObject(canvas, target) {
//     if (target.noteIcon) {
//         canvas.remove(target.noteIcon);
//         target.noteIcon = null;
//     }
// }

