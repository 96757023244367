<template>
    <div class="drag-container">
      <div
          v-for="button in buttons"
          :key="button.id"
          draggable="true"
          @dragstart="onDragStart($event, button)"
          class="drag-item"
          :title="button.name + ' Przeciągnij na płótno'"
      >
        <img :src="require(`@/assets/icons/${button.icon}`)" alt="icon" :class="{ 'inverted': $theme.invertIcon }"/>
      </div>
    </div>

</template>

<script>

export default {
  name: 'ToolbarComponent',
  data() {
    return {
      buttons: [
        { id: 1, icon: 'rectangle.svg', type: 'rectangle', name: 'Prostokąt bez wypełnienia.' },
        { id: 2, icon: 'ellipse.svg', type: 'ellipse', name: 'Elipsa bez wypełnienia.' },
        { id: 3, icon: 'rectangle_fill.svg', type: 'rectangleFill', name: 'Prostokąt z wypełnieniem.' },
        { id: 4, icon: 'ellipse_fill.svg', type: 'ellipseFill', name: 'Elipsa z wypełnieniem.' },
        { id: 5, icon: 'arrow.svg', type: 'arrow', name: 'Strzałka.'},
        { id: 6, icon: 's_line.svg', type: 'simpleLine', name: 'Linia prosta.' },
        { id: 7, icon: 'line.svg', type: 'zigLine', name: 'Krzywa.' },
        { id: 8, icon: 'measure.svg', type: 'measure', name: 'Miarka' },
        { id: 9, icon: 'text.svg', type: 'textbox', name: 'Pole tekstowe.' },

      ]
    };
  },
  props: {
    message: {
      type: String,
      default: ''
    }
  },
  methods: {
    onDragStart(event, button) {
      event.dataTransfer.setData('shapeType', button.type);
    },

  }
};
</script>

<style scoped>

.drag-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  min-height: 99%;
  padding: 0;
  box-sizing: border-box;
}

.drag-item {
  border: none;
  background: none;
  cursor: grab;
  margin: 2px;
  padding: 0;
  height: 28px;
  text-align: center;
}

@media (min-width: 1280px) {
  .drag-container {
    flex-wrap: nowrap;
  }

  .drag-item {
    flex-basis: auto;
  }
}


</style>
