<template>
  <div class="parent-zoom">
    <button class="rect-button" @click="zoomUp" :disabled="!canZoomUp" title="Przybliż">
      <img src="@/assets/icons/zoom_in.svg" alt="Przybliż" :class="{ 'inverted': $theme.invertIcon }"/>
    </button>
<!--    <button class="rect-button position" @click="zoomOne" title="Widok bez skalowania">-->
<!--      <img src="@/assets/icons/zoom_one.svg" alt="Widok bez skalowania" />-->
<!--    </button>-->
    <button class="rect-button" @click="zoomDown" :disabled="!canZoomDown" title="Oddal" >
      <img src="@/assets/icons/zoom_out.svg" alt="Oddal" :class="{ 'inverted': $theme.invertIcon }"/>
    </button>
    <button class="rect-button" @click="position0"  title="Przesuń do punktu startowego. (Alt + S)" >
      <img src="@/assets/icons/center_view.svg" alt="Przesuń do punktu startowego" :class="{ 'inverted': $theme.invertIcon }"/>
    </button>
    <button class="rect-button" @click="zoomToFit"  title="Zobacz cały rysunek. (Alt + A)" >
      <img src="@/assets/icons/zoom_long_out.svg" alt="Zobacz cały rysunek" :class="{ 'inverted': $theme.invertIcon }"/>
    </button>
    <button class="rect-button" @click="redrawCanvasWithHighQuality"  title="Odśwież kontury rysunku" >
      <img src="@/assets/icons/rebrush.svg" alt="Odśwież kontury rysunku" :class="{ 'inverted': $theme.invertIcon }"/>
    </button>

  </div>
</template>

<script>

// import {fabric} from "fabric";

export default {
  name: 'ZoomComponent',
  props: {
    canvas: {
      type: Object,
      required: true,
    },
    viewportWidth: {
      type: Number,
      required: true,
    },
    viewportHeight: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      min_zoom: 0.4,
      redrawing: false,
      move_active: false,
    };
  },
  computed: {
    canZoomUp() {
      if (this.canvas) {
        return this.canvas.getZoom() < 8;
      }
      return false;
    },
    canZoomDown() {
      if (this.canvas) {
        return this.canvas.getZoom() > this.min_zoom;
      }
      return false;
    },
  },
  watch: {
    canvas: {
      immediate: true,
      handler(newCanvas) {
        if (newCanvas) {
          const zoom = this.canvas.getZoom()
          const outerWidth = this.canvas.getWidth();
          const outerHeight = this.canvas.getHeight();
          const innerWidth = this.viewportWidth * zoom;
          const innerHeight = this.viewportHeight * zoom;

          if (innerWidth < outerWidth || innerHeight < outerHeight) {
            this.min_zoom = zoom;
          }
        }
      },
    },
  },
  mounted() {
    window.addEventListener('keydown', this.handleKeyDown);
  },
  methods: {
    handleKeyDown(event) {
      if (event.altKey && event.key === 's') {
        this.position0();
      }
      if (event.altKey && event.key === 'a') {
        this.zoomToFit();
      }

    },
    zoomUp() {
      const zoom = this.canvas.getZoom();
      let newZoom = zoom + 0.2;
      if (newZoom > 8) newZoom = 8; // Maksymalne ograniczenie zoomu

      const outerWidth = this.canvas.getWidth();
      const outerHeight = this.canvas.getHeight();
      const innerWidth = this.viewportWidth * newZoom;
      const innerHeight = this.viewportHeight * newZoom;

      // Ustawienie nowego zoomu
      this.canvas.setZoom(newZoom);

      // Przesunięcie widoku na podstawie nowego zoomu
      const vpt = this.canvas.viewportTransform;

      // Sprawdzenie, czy wewnętrzna szerokość i wysokość są większe niż zewnętrzne
      if (innerWidth <= outerWidth) {
        vpt[4] = (outerWidth - innerWidth) / 2;
      } else {
        if (vpt[4] > 0) vpt[4] = 0;
        if (vpt[4] < outerWidth - innerWidth) vpt[4] = outerWidth - innerWidth;
      }

      if (innerHeight <= outerHeight) {
        vpt[5] = (outerHeight - innerHeight) / 2;
      } else {
        if (vpt[5] > 0) vpt[5] = 0;
        if (vpt[5] < outerHeight - innerHeight) vpt[5] = outerHeight - innerHeight;
      }

      this.canvas.requestRenderAll();
    },
    zoomDown() {
      const zoom = this.canvas.getZoom();
      let newZoom = zoom - 0.2;
      if (newZoom < 0.4) newZoom = 0.4; // Minimalne ograniczenie zoomu

      const outerWidth = this.canvas.getWidth();
      const outerHeight = this.canvas.getHeight();
      const innerWidth = this.viewportWidth * newZoom;
      const innerHeight = this.viewportHeight * newZoom;

      // Ustawienie nowego zoomu
      this.canvas.setZoom(newZoom);

      // Przesunięcie widoku na podstawie nowego zoomu
      const vpt = this.canvas.viewportTransform;

      // Sprawdzenie, czy wewnętrzna szerokość i wysokość są większe niż zewnętrzne
      if (innerWidth <= outerWidth) {
        vpt[4] = (outerWidth - innerWidth) / 2;
      } else {
        if (vpt[4] > 0) vpt[4] = 0;
        if (vpt[4] < outerWidth - innerWidth) vpt[4] = outerWidth - innerWidth;
      }

      if (innerHeight <= outerHeight) {
        vpt[5] = (outerHeight - innerHeight) / 2;
      } else {
        if (vpt[5] > 0) vpt[5] = 0;
        if (vpt[5] < outerHeight - innerHeight) vpt[5] = outerHeight - innerHeight;
      }

      this.canvas.requestRenderAll();
    },
    zoomToFit() {
      const outerWidth = this.canvas.getWidth();
      const outerHeight = this.canvas.getHeight();
      const innerWidth = this.viewportWidth;
      const innerHeight = this.viewportHeight;

      const scaleX = outerWidth / innerWidth;
      const scaleY = outerHeight / innerHeight;
      const newZoom = Math.min(scaleX, scaleY);

      this.canvas.setZoom(newZoom);

      const scaledWidth = innerWidth * newZoom;
      const scaledHeight = innerHeight * newZoom;

      const vpt = this.canvas.viewportTransform;

      vpt[4] = (outerWidth - scaledWidth) / 2;
      vpt[5] = (outerHeight - scaledHeight) / 2;

      this.canvas.getObjects().forEach((obj) => {
        obj.setCoords();
      });

      this.canvas.requestRenderAll();
    },
    redrawCanvasWithHighQuality() {
      if(this.redrawing) return;
      this.redrawing = true;
      this.canvas.getObjects().forEach((obj) => {
        if (obj.type === 'image' || obj.type === 'svg' || obj.type === 'group' || obj.isType('svg')) {
          obj._originalObjectCaching = obj.objectCaching;
          obj.objectCaching = false;
          obj.dirty = true;
        }
      });

      this.canvas.requestRenderAll();

      setTimeout(() => {
        this.canvas.getObjects().forEach((obj) => {
          if (obj.type === 'image' || obj.type === 'svg' || obj.type === 'group' || obj.isType('svg')) {
            obj.objectCaching = obj._originalObjectCaching;
          }
        });
        this.redrawing = false;
      }, 10);
    },
    zoomOne() {
      this.canvas.setZoom(1);
    },
    position0() {
      this.canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
    },
  },
};
</script>

<style>
@import '@/assets/themes.css';

.parent-zoom {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 4px;
}



</style>