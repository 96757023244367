<template>
  <ModalComponent
      :isVisible="isModalVisible"
      :title="modalTitle"
      :primary-text="modalConfirmText"
      :alternative-text="modalCancelText"
      @primary="handlePrimary"
      @alternative="handleAlternative"
      @close="handleClose"
  >
    <template v-slot:default>
      <div v-html="modalMessage"></div>
    </template>
  </ModalComponent>
  <div class="left-inner-panel">
    <div class="input-container">
      <label for="projectNameText">NAZWA PROJEKTU:</label>
      <input type="text" id="projectNameText" v-model="projectNameText" maxlength="24" class="custom-input" @focus="clearClipboard">
    </div>
    <div class="counter-container">
      <span :class="{ 'red-text': minutes >= 10 }">
        Czas od ostatniego zapisu: {{ minutes }} min.
      </span>
    </div>
    <div class="buttons-container">
      <button @click="serializeCanvas" title="Pobierz plik zapisu" class="rect-button">
        <img src="@/assets/icons/download_save.svg" alt="Pobierz plik zapisu" :class="{ 'inverted': $theme.invertIcon }"/>
      </button>
      <button @click="deserializeCanvas" title="Wczytaj plik zapisu" class="rect-button">
        <img src="@/assets/icons/load_save.svg" alt="Wczytaj plik zapisu" :class="{ 'inverted': $theme.invertIcon }"/>
      </button>
      <button @click="clearCanvas" title="Wyczyść płótno" class="rect-button">
        <img src="@/assets/icons/clear_all.svg" alt="Wyczyść płótno" :class="{ 'inverted': $theme.invertIcon }"/>
      </button>

      <button @click="printCanvas" title="Pobierz plik PDF" class="rect-button">
        <img src="@/assets/icons/download_pdf.svg" alt="Pobierz plik PDF" :class="{ 'inverted': $theme.invertIcon }"/>
      </button>
      <button @click="canvasToSVG" title="Wyświetl w nowym oknie plik SVG" class="rect-button">
        <img src="@/assets/icons/download_svg.svg" alt="Wyświetl w nowym oknie plik SVG" :class="{ 'inverted': $theme.invertIcon }"/>
      </button>
      <button @click="printNotes" title="Zapisz notatki" class="rect-button">
        <img src="@/assets/icons/notes_print.svg" alt="Zapisz notatki" :class="{ 'inverted': $theme.invertIcon }"/>
      </button>
    </div>
    <hr>
    <ItemsComponent :title="contentTitle"
                    :icons="contentItems"
                    :sections="groupsSections"
                    :icons-set-index="contentIndex"
                    @clear-clipboard="clearClipboard"
    />
  </div>
</template>

<script>
import ModalComponent from '@/components/ModalComponent.vue';
import ItemsComponent from "@/components/ItemsComponent.vue";
import api from "@/utils/api";
import {mapState} from "vuex";

export default {
  name: "LeftPanel",
  emits: ['clear-clipboard'],
  components: {ItemsComponent, ModalComponent },
  props: {
    contentTitle: {
      type: String,
      required: true,
    },
    contentIndex: {
      type: Number,
      required: true,
    },
    projectName: {
      type: String,
      required: true,
    }
  },
  watch: {
    projectName: {
      handler(newVal) {
        this.projectNameText = newVal;
      },
      immediate: true,
    }
  },
  computed: {
    ...mapState([ "canvasID" ]),
    showSaveDialog() {
      return this.minutes % 15 === 0 && this.notShowYet;
    }
  },
  data() {
    return {
      isModalVisible: false,
      download: false,
      getSVG: false,
      notShowYet: true,
      projectNameText: '',
      modalTitle: '',
      modalMessage: '',
      modalConfirmText: '',
      modalCancelText: '',
      modalType: 0,
      minutes: 0,
      intervalId: null,
      contentItems: [],
      groupsSections: [],
    };
  },
  async mounted() {
    await this.getObjectsData();
    await this.getSectionsData();
    this.startTimer();
  },
  beforeUnmount() {
    this.clearTimer();
  },
  methods: {
    async getObjectsData() {
      try {
        const icons = await api.getData();
        this.contentItems = this.groupArrayByField(icons, 'group')
      } catch (error) {
        console.error('Błąd podczas pobierania danych:', error);
      }
    },
    async getSectionsData() {
      try {
        const sections = await api.getSections();
        this.groupsSections = this.groupArrayByField(sections, 'group', {id: -1, name: 'Wszystkie', number:0, group: 0,})
      } catch (error) {
        console.error('Błąd podczas pobierania danych:', error);
      }
    },
    clearClipboard() {
      this.$emit('clear-clipboard');
    },
    startTimer() {
      this.intervalId = setInterval(() => {
        this.minutes++;
        if (this.showSaveDialog) {
          this.showDownloadModal(false);
        }
      }, 60000);
    },
    clearTimer() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
        this.intervalId = null;
      }
    },
    resetTimer() {
      this.clearTimer();
      this.minutes = 0;
      this.notShowYet = true;
      this.startTimer();
    },
    // eslint-disable-next-line no-unused-vars
    groupArrayByField(array, field, initVal=null) {
      // Initialize the acc object with keys 1 to 5
      const acc = {};
      for (let i = 1; i < 6; i++) {
        if (initVal !== null) {
          acc[i] = [initVal];
        } else {
          acc[i] = [];
        }
      }

      // Group the array elements by the field
      array.forEach(obj => {
        const key = obj[field];
        if (acc[key]) {
          acc[key].push(obj);
        }
      });

      // Return the grouped values
      return Object.values(acc);
    },
    handlePrimary() {
      this.isModalVisible = false;
      if (this.modalType === 0) {
        this.$store.dispatch('clearCanvas', this.canvasID);
      } else if (this.modalType === 2) {
        this.$store.dispatch('showA3SVG');
      } else if (this.modalType === 3) {
        this.$store.dispatch('serializeFromCanvas', {name: this.projectNameText, project: true} );
        this.resetTimer();
      } else if (this.modalType === 4) {
        this.$store.dispatch('deserializeToCanvas');
      } else if (this.download) {
        this.$store.dispatch('createA3PDF', this.projectNameText);
      } else {
        // this.$store.dispatch('printA4Canvas');
        this.$store.dispatch('createA3PDF', this.projectNameText);
      }
    },
    handleAlternative() {
      this.isModalVisible = false;
      // if (this.modalType === 1) {
      //   if (this.download) {
      //     this.$store.dispatch('createA3PDF', this.projectNameText);
      //   } else {
      //     // this.$store.dispatch('printA3Canvas');
      //     this.$store.dispatch('createA3PDF', this.projectNameText);
      //   }
      // } else
      if (this.modalType === 2) {
        this.$store.dispatch('downloadA3SVG', this.projectNameText);
      } else if (this.modalType === 3) {
        this.$store.dispatch('serializeFromCanvas', {name: this.projectNameText, project: false} );
      }
    },
    handleClose() {
      this.isModalVisible = false;
      this.notShowYet = false;
    },
    clearCanvas() {
      this.showCancelModal();
    },
    deserializeCanvas() {
      this.showUploadModal();
    },
    serializeCanvas() {
      this.showDownloadModal();
    },
    canvasToSVG() {
      this.showSVGModal();
    },
    printCanvas() {
      this.download = false;
      this.showPdfModal();
    },
    printNotes() {
      this.$store.dispatch('printNotes');
    },
    showCancelModal() {
      this.modalTitle = 'Potwierdzenie';
      this.modalMessage = 'Czy na pewno chcesz całkowicie wyczyścić aktualnie wybrany rysunek?';
      this.modalConfirmText = 'Wyczyść';
      this.modalCancelText = 'Anuluj';
      this.isModalVisible = true;
      this.modalType = 0;
    },
    showPdfModal() {
      this.modalTitle = 'Wybór akcji';
      this.modalMessage = 'Wybierz odpowiednią opcję';
      this.modalConfirmText = 'Pobierz';
      this.modalCancelText = 'Anuluj';
      this.isModalVisible = true;
      this.modalType = 1;
    },
    showSVGModal() {
      this.modalTitle = 'Wybór akcji';
      this.modalMessage = 'Możesz otworzyć plik SVG w przeglądarce lub pobrać na swój komputer';
      this.modalConfirmText = 'Zobacz';
      this.modalCancelText = 'Pobierz';
      this.isModalVisible = true;
      this.modalType = 2;
    },
    showDownloadModal(standard=true) {
      if (standard) {
        this.downloadModalType('Co chcesz zapisać?');
      } else {
        this.downloadModalType('Od ostatniego zapisu minęło 15 minut!');
      }
    },
    downloadModalType(modalTitle) {
      this.modalTitle = modalTitle;
      this.modalMessage = 'Możesz utworzyć plik zapisu dla projektu lub pojedynczego rysunku. <br> Wybierz co chcesz zapisać:';
      this.modalConfirmText = 'Projekt';
      this.modalCancelText = 'Rysunek';
      this.isModalVisible = true;
      this.modalType = 3;
    },
    showUploadModal() {
      this.modalTitle = 'Potwierdzenie';
      this.modalMessage = 'Możesz wczytać plik zapisu dla projektu lub pojedynczego rysunku. <br> ' +
          'Jeśli wybierzesz plik projektu, wszelkie postępy we wszystkich rysunkach zostaną wymazane. <br> ' +
          'Jeśli wybierzesz plik rysunku zostanie on zapisany do aktualnie wybranego rysunku, a jego obecna zawartość zostanie usunięta. <br>' +
          'Zalecamy stworzenie pliku zapisu (naciśnij "Anuluj") jeśli nie masz pewności.';
      this.modalConfirmText = 'Wczytaj';
      this.modalCancelText = 'Anuluj';
      this.isModalVisible = true;
      this.modalType = 4;
    },
  }
};
</script>

<style scoped>
@import '@/assets/themes.css';

.left-inner-panel {
  width: 200px;
  margin: 0 auto;
  text-align: center;
}

.input-container {
  display: block;
  padding: 4px;
  margin-bottom: 4px;
}

.counter-container {
  font-size: 12px;
}

label {
  display: block;
  margin: 10px;
  font-weight: bold;
}

.input-container .custom-input {
  width: 184px;
  padding: 5px;
  box-sizing: border-box;
  border-radius: var(--border-radius);
  border: var(--buttons-border-width) solid var(--component-border-color);
}

.red-text {
  color: var(--buttons-lock-color);
}

.buttons-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 15px;
  padding: 10px;
}

.buttons-container button {
  display: flex;
  width: 45px;
  height: 45px;
  margin: 5px;
  padding: 3px;
  background-color: var(--left-menu-buttons-color);
}
.buttons-container button:hover {
  background-color: var(--buttons-hover-color);
}

.buttons-container button img {
  width: auto;
  height: auto;
}

hr {
  border-color: var(--thin-border-color)
}

</style>