<template>
  <div class="button-container">
    <button
        v-for="(button, index) in buttons"
        :key="button.name"
        :class="{'active': button.active}"
        @click="toggleActive(button, index)"
    >
      {{ button.name }}
    </button>
  </div>
</template>

<script>
export default {
  props: {
    activeIndex: Number,
  },
  data() {
    return {
      independentButtonIndex: 5, // Should be on the last position
      buttons: [
        { id: 0, name: 'OBUDOWY', active: true },
        { id: 1, name: 'APARATURA', active: false },
        { id: 2, name: 'SCHEMAT ELEKTRYCZNY', active: false },
        { id: 3, name: 'MASKOWNICE', active: false },
        { id: 4, name: 'WIDOK ELEWACJI', active: false },
        { id: 5, name: 'NIEZALEŻNY', active: false },
      ],
    };
  },
  mounted() {
    this.buttons[this.activeIndex].active = true;
    this.setSimpleButtons(this.activeIndex);
  },
  methods: {
    toggleActive(button, index) {
      if (index === this.independentButtonIndex) {
        button.active = !button.active;
      } else {
        button.active = true;
      }

      this.setSimpleButtons(index)

      if(button.active) {
        this.$emit('update-title', button.name, button.id, this.buttons[this.independentButtonIndex].active);
        // this.$emit('update-active-tab-index', index);
      } else {
        const lastButton = this.buttons.slice().reverse().find(button => button.active);
        this.$emit('update-title', lastButton.name, lastButton.id, this.buttons[this.independentButtonIndex].active);
        // const reversedIndex = this.buttons.slice().reverse().findIndex(button => button.active);
        // const lastActiveIndex = reversedIndex === -1 ? -1 : this.buttons.length - 1 - reversedIndex;
        // this.$emit('update-active-tab-index', lastActiveIndex);
      }
    },
    setSimpleButtons(index) {
      if (index < this.independentButtonIndex) {
        for (let i = 0; i < index; i++) {this.buttons[i].active = true;}
        for (let i = index+1; i < this.buttons.length-1; i++) {this.buttons[i].active = false;}
      }
    }
  }
};
</script>

<style scoped>
.button-container {
  display: flex;
  align-items: center;
  height: 100%;
}

button {
  padding: 10px 12px;
  margin: 0 5px;
  width: 120px;
  min-height: 50px;
  font-size: 12px;
  font-style: normal;
  color: var(--primary-text-color);
  background-color: var(--tab-buttons-color);
  border: 1px solid var(--buttons-border-color);
  border-radius: var(--big-buttons-border-radius);
  cursor: pointer;
  transition: background-color 0.3s;
}

button.active {
  color: var(--inverse-text-color);
  background-color: var(--buttons-active-color);
}

button:hover {
  background-color: var(--buttons-hover-color);
}

button:last-child {
  display: none;
}

</style>
