<script>

export default {
  methods: {
    toggleMenu() {
      this.$layout.toggleMenu();
      console.log(this.$layout.current)
    }
  },
  mounted() {
    console.log('Current layout:', this.$layout.current)
  }
}

</script>

<template>
  <div class="parent-menu">
<!--    <button-->
<!--        class="rect-button"-->
<!--        :class="{ 'active': $layout.isDevMenu }"-->
<!--        @click="toggleMenu"-->
<!--        title="Zmień górne menu"-->
<!--    >-->
<!--      <img src="@/assets/icons/b.svg" alt="Zmień górne menu" :class="{ 'inverted': $theme.invertIcon }"/>-->
<!--    </button>-->
  </div>
</template>

<style scoped>
@import '@/assets/themes.css';

.parent-menu {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 150px;
  padding: 4px;
}

</style>