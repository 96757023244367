import { createStore } from 'vuex';

export default createStore({
    state: {
        isClearDemanded: false,
        isSerializeDemanded: false,
        isDeserializeDemanded: false,
        isPDFDemanded: false,
        isPrintDemanded: false,
        isNotesDemanded: false,
        isSVGDemanded: false,
        iSVGTypeDemanded: 0,
        iPDFTypeDemanded: 0,
        projectName: 'Projekt',
        isWholeProject: false,
        canvasID: 0,
        canvasIDToClear: 0,
        areCanvasButtonsUpdated: false,
        canvasButtons: [],
    },
    mutations: {
        SET_CANVAS(state, canvasID) {
            state.canvasID = canvasID;
        },
        CLEAR_CANVAS(state, canvasID) {
            state.isClearDemanded = true;
            state.canvasIDToClear = canvasID;
        },
        RESET_CANVAS(state) {
            state.isClearDemanded = false;
        },
        SERIALIZE_DEMANDED(state, payload) {
            state.isSerializeDemanded = true;
            state.projectName = payload.name;
            state.isWholeProject = payload.project;
        },
        SERIALIZE_FINISHED(state) {
            state.isSerializeDemanded = false;
        },
        DESERIALIZE_DEMANDED(state) {
            state.isDeserializeDemanded = true;
        },
        DESERIALIZE_FINISHED(state) {
            state.isDeserializeDemanded = false;
        },
        PDF_A4_DEMANDED(state, name) {
            state.isPDFDemanded = true
            state.iPDFTypeDemanded = 1;
            state.projectName = name
        },
        PDF_A3_DEMANDED(state, name) {
            state.isPDFDemanded = true
            state.iPDFTypeDemanded = 2;
            state.projectName = name
        },
        PDF_READY(state) {
            state.isPDFDemanded = false;
            state.isPDFDemanded = 0;
        },
        PRINT_A4_DEMANDED(state) {
            state.isPrintDemanded = true
            state.iPDFTypeDemanded = 1;
        },
        PRINT_A3_DEMANDED(state) {
            state.isPrintDemanded = true
            state.iPDFTypeDemanded = 2;
        },
        PRINT_READY(state) {
            state.isPrintDemanded = false;
        },
        NOTES_DEMANDED(state) {
            state.isNotesDemanded = true;
        },
        NOTES_READY(state) {
            state.isNotesDemanded = false;
        },
        SVG_A3_DOWNLOAD_DEMANDED(state, name) {
            state.isSVGDemanded = true
            state.iSVGTypeDemanded = 1;
            state.projectName = name
        },
        SVG_A3_SHOW_DEMANDED(state) {
            state.isSVGDemanded = true
            state.iSVGTypeDemanded = 2;
        },
        SVG_READY(state) {
            state.isSVGDemanded = false;
            state.iSVGTypeDemanded = 0;
        },
        UPDATE_CANVAS_BUTTONS (state, canvasButtons) {
            state.areCanvasButtonsUpdated = true;
            state.canvasButtons = canvasButtons;
        },
        CANVAS_BUTTONS_UPDATED(state) {
            state.areCanvasButtonsUpdated = false;
        }
    },
    actions: {
        setCanvasIndex({ commit }, canvasID) {
            commit('SET_CANVAS', canvasID);
        },
        clearCanvas({ commit }, canvasID) {
            commit('CLEAR_CANVAS', canvasID);
        },
        resetCanvas({ commit }) {
            commit('RESET_CANVAS');
        },
        serializeFromCanvas({ commit }, payload) {
            commit('SERIALIZE_DEMANDED', payload);
        },
        serializeReady({ commit }) {
            commit('SERIALIZE_FINISHED');
        },
        deserializeToCanvas({ commit }) {
            commit('DESERIALIZE_DEMANDED');
        },
        deserializeReady({ commit }) {
            commit('DESERIALIZE_FINISHED');
        },
        createA4PDF({ commit }, name) {
            commit('PDF_A4_DEMANDED', name);
        },
        createA3PDF({ commit }, name) {
            commit('PDF_A3_DEMANDED', name);
        },
        pdfReady({ commit }) {
            commit('PDF_READY');
        },
        printA3Canvas({ commit }) {
            commit('PRINT_A3_DEMANDED');
        },
        printA4Canvas({ commit }) {
            commit('PRINT_A4_DEMANDED');
        },
        printReady({ commit }) {
            commit('PRINT_READY');
        },
        printNotes({ commit }) {
            commit('NOTES_DEMANDED');
        },
        notesReady({ commit }) {
            commit('NOTES_READY');
        },
        downloadA3SVG({ commit }, name) {
            commit('SVG_A3_DOWNLOAD_DEMANDED', name);
        },
        showA3SVG({ commit }) {
            commit('SVG_A3_SHOW_DEMANDED');
        },
        svgReady({ commit }) {
            commit('SVG_READY');
        },
        updateCanvasButtons({ commit }, buttons) {
            commit('UPDATE_CANVAS_BUTTONS', buttons);
        },
        canvasButtonsUpdated({ commit }) {
            commit('CANVAS_BUTTONS_UPDATED');
        },
    }
});