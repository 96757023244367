<template>
  <div class="my-container">
    <h1 class="title">{{ title }}</h1>
    <CustomDropdown
        ref="customDropdown"
        :options="dropDownList"
        placeholder="Wybierz grupę"
        backgroundColor="#f0f0f0"
        textColor="#333333"
        borderColor="#999999"
        fontSize="14px"
        width="184px"
        @selected="handleSelection"
    />
    <input
        ref="searchInput"
        type="search"
        v-model="searchQuery"
        placeholder="Wyszukaj po nazwie"
        class="search-input"
        @focus="clearClipboard"
    />
    <div class="content" ref="contentDiv">
      <div v-if="limitedIcons.length === 0" class="loading-message">
        Trwa<br />ładowanie<br />aparatury
      </div>
      <div v-for="icon in limitedIcons"
           :key="icon"
           draggable="true"
           @dragstart="onDragStart($event, icon)"
           class="icon-figures">
        <figure class="icon-figure">
          <img :src="'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(icon.svg_string)" :alt="icon.name" />
          <figcaption>{{ icon.name }}</figcaption>
        </figure>
      </div>
    </div>
  </div>
</template>

<script>
import CustomDropdown from "@/components/CustomDropDown.vue";

export default {
  components: { CustomDropdown },
  props: {
    title: { type: String, required: true },
    icons: { type: Array, required: true },
    sections: { type: Array, required: true },
    iconsSetIndex: { type: Number, required: true }
  },
  watch: {
    iconsSetIndex: {
      immediate: true,
      handler() {
        // Resetuj dropdown do stanu początkowego
        if (this.$refs.customDropdown) {
          this.$refs.customDropdown.reset();
        }

        // Resetuj stan lokalny (pełne renderowanie wyłączone)
        this.fullRender = false;
        this.showByGroup = null;
        this.actualSection = -1;
        this.searchQuery = '';
      },
    },
  },
  data() {
    return {
      showByGroup: null,
      actualSection: -1,
      searchQuery: '',
      dropdownOptions: [],
      initialLimit: 30, // Liczba elementów renderowanych na starcie
      fullRender: false, // Czy renderować wszystkie elementy
    };
  },
  computed: {
    filteredIcons() {
      if (this.iconsSetIndex < this.icons.length && typeof this.icons[this.iconsSetIndex].filter === 'function') {
        const { icons, iconsSetIndex, searchQuery, actualSection, showByGroup } = this;
        const lowercaseQuery = searchQuery.toLowerCase();

        return icons[iconsSetIndex].filter(({ name, not_visible, section }) => {
          const baseCondition = name.toLowerCase().includes(lowercaseQuery) && !not_visible;
          return showByGroup ? baseCondition && section === actualSection : baseCondition;
        });
      } else {
        return [];
      }
    },
    limitedIcons() {
      return this.fullRender ? this.filteredIcons : this.filteredIcons.slice(0, this.initialLimit);
    },
    dropDownList() {
      if (this.iconsSetIndex < this.sections.length && typeof this.sections[this.iconsSetIndex].filter === 'function') {
        return this.sections[this.iconsSetIndex];
      } else {
        return [];
      }
    },
  },
  methods: {
    clearClipboard() {
      this.$emit('clearClipboard');
    },
    handleSelection(value) {
      this.showByGroup = value === -1 ? null : value;
      this.actualSection = value;
      this.searchQuery = '';
      this.$refs.contentDiv.scrollTop = 0;

      // Wyłącz ograniczenie liczby elementów dla dowolnej wybranej grupy
      this.fullRender = true;
    },
    onDragStart(event, icon) {
      const { svg_string: svg, name, editable_horizontal: editable_hor, editable_vertical: editable_ver, free_rotation: rotation } = icon;

      let json;
      if (icon.attach) {
        const { svg_string: attach_svg, name: attach_name, editable_h: attach_editable_h, editable_v: attach_editable_v, rotation: attach_rotation, group: attach_group } = icon.attach;
        json = JSON.stringify({ svg, name, editable_hor, editable_ver, rotation, attach_svg, attach_name, attach_editable_h, attach_editable_v, attach_rotation, attach_group });
      } else {
        json = JSON.stringify({ svg, name, editable_hor, editable_ver, rotation });
      }
      event.dataTransfer.setData('text/plain', json);
    },
  },
};
</script>


<style scoped>
@import '@/assets/themes.css';

.my-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title {
  margin: 0;
  font-size: 16px;
  padding: 8px 0;
  color: var(--important-text-color);
}

.search-input {
  display: block;
  width: 184px;
  height: 30px;
  padding: 5px;
  box-sizing: border-box;
  margin-top: 10px;
  border-radius: 10px;
  background-color: var(--component-background);
  border: var(--buttons-border-width) solid var(--component-border-color);
}

.content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
  flex: 1;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 20px;
  margin-left: 2px;
  min-height: 180px;
  max-height: calc(100vh - 580px);
  width: 184px;
  padding: 10px 5px;
  background: var(--component-background);
  border-radius: var(--border-radius);
  border: var(--buttons-border-width) solid var(--component-border-color);
}

.content::-webkit-scrollbar {
  width: 1px;
}

.content::-webkit-scrollbar-thumb {
  background-color: var(--buttons-color);
  border-radius: var(--border-radius);
}

.content::-webkit-scrollbar-track {
  background: var(--primary-background);
  border-radius: var(--border-radius);
}

.loading-message {
  font-size: 20px;
  line-height: 1.5;
  width: 184px;
}

.icon-figures {
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-figure {
  margin: 0;
  width: 75px;
  text-align: center;
  cursor: grab;
}

.icon-figure img {
  width: 100%;
  height: auto;
  display: block;
  max-height: 80px;
  object-fit: contain;
}

.icon-figure figcaption {
  margin-top: 5px;
  font-size: 14px;
}

</style>
