<template>
  <div class="parent-hand">
    <button
          class="rect-button"
          :class="{ 'active': move_active }"
          @click="canvasMove"
          title="Przełącz w tryb przesuwania. (Alt + H)"
      >
        <img src="@/assets/icons/hand.svg" alt="Przełącz w tryb przesuwania" :class="{ 'inverted': $theme.invertIcon }"/>
      </button>
  </div>
</template>

<script>

// import {fabric} from "fabric";

export default {
  name: 'HandButton',
  data() {
    return {
      min_zoom: 0.4,
      move_active: false,
    };
  },
  mounted() {
    window.addEventListener('keydown', this.handleKeyDown);
  },
  methods: {
    handleKeyDown(event) {
      if (event.altKey && event.key === 'h') {
        this.canvasMove();
      }
    },
    canvasMove() {
      this.move_active = !this.move_active;
      this.$emit('update:moveActive', this.move_active);
    }
  },
};
</script>

<style scoped>
@import '@/assets/themes.css';

.parent-hand {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 50px;
  padding: 4px;
}

</style>