// theme_manager.js
import { ref, computed } from 'vue'

const theme = ref(localStorage.getItem('theme') || 'light')

const theme_manager = {
    install(app) {
        const isDarkTheme = computed(() => theme.value === 'blue')
        const invertIcon = computed(() => isDarkTheme.value)

        app.config.globalProperties.$theme = {
            get current() { return theme.value },
            set current(value) { theme.value = value },
            get isDarkTheme() { return isDarkTheme.value },
            get invertIcon() { return invertIcon.value },
            toggleTheme() {
                this.current = this.isDarkTheme ? 'light' : 'blue'
                document.documentElement.setAttribute('data-theme', this.current)
                localStorage.setItem('theme', this.current)
            }
        }

        document.documentElement.setAttribute('data-theme', theme.value)
    }
}

export default theme_manager