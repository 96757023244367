import {fabric} from "fabric";
import {v4 as uv4} from "uuid";

export function addIcon(canvas, name, svgText, left, top, editable_ver, editable_hor, rotation, active_index, drawing_index, visible=true, selectable=true) {
    return new Promise((resolve, reject) => {
        try {
            const parser = new DOMParser();
            const svgDocument = parser.parseFromString(svgText, 'image/svg+xml');
            const svgElement = svgDocument.documentElement;

            // Usuwanie specyficznych przestrzeni nazw Visio
            svgElement.removeAttribute('xmlns:v');
            svgElement.removeAttribute('xmlns:ev');

            const serializer = new XMLSerializer();
            const updatedSvgText = serializer.serializeToString(svgElement);
            fabric.loadSVGFromString(updatedSvgText, (objects, options) => {
                const svgGroup = fabric.util.groupSVGElements(objects, options);
                svgGroup.set({
                    left: left - svgGroup.width / 2,
                    top: top - svgGroup.height / 2,
                    selectable: selectable,
                    hasBorders: true,
                    hasControls: true,
                    lockScalingY: !editable_ver,
                    lockScalingX: !editable_hor,
                    visible: visible,
                    name: name,
                    id: uv4(),
                    lockSelectability: !selectable,
                    objectCaching: true,
                    isFromBase: true,
                });
                const editable = editable_ver && editable_hor;
                svgGroup.setControlsVisibility({
                    mt: editable || editable_ver,
                    mb: editable || editable_ver,
                    ml: editable || editable_hor,
                    mr: editable || editable_hor,
                    tl: editable,
                    tr: editable,
                    bl: editable,
                    br: editable,
                    mtr: rotation
                });
                canvas.addObjectToLayer(svgGroup, active_index, drawing_index);

                resolve(svgGroup);
            });
        } catch (error) {
            reject(error);
        }
    });
}