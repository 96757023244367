export default {
    async getData() {
        const response = await fetch('/api/objects/');
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        return await response.json();
    },
    async getSections() {
        const response = await fetch('/api/sections/');
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        return await response.json();
    },
    async getHelp() {
        const response = await fetch('/api/help/');
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        return await response.json();
    },
}