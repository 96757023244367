<template>
  <div class="button-wrapper">
    <button class="main-button"
            :class="{active: activeCanvas}"
            @click="performMainAction">
      {{ label }}
    </button>
    <button ref="contextBtn" class="context-menu-button"
            :class="{active: activeClass}"
            @click="toggleContextMenu">
      <div class="context-menu-icon">
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
      </div>
    </button>
  </div>
</template>

<script>
export default {
  emits: [ "show-context-menu", "showed-drawing-index" ],
  props: {
    activeCanvas: Boolean,
    activeClass: Boolean,
    innerIndex: Number,
    label: {
      type: String,
      default: 'Rysunek'
    }
  },
  data() {
    return {
      leftPosition: 0,
    };
  },
  methods: {
    performMainAction() {
      this.$emit('showed-drawing-index', this.innerIndex);
    },
    toggleContextMenu() {
      this.leftPosition = this.$refs.contextBtn.getBoundingClientRect().left;
      this.$emit('show-context-menu', this.leftPosition);
    },
  },
};
</script>

<style scoped>
.button-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}
.main-button,
.context-menu-button {
  height: 40px;
  border-radius: var(--big-buttons-border-radius);
  color: var(--primary-text-color);
  background-color: var(--tab-buttons-color);
  border: 1px solid var(--buttons-border-color);
  cursor: pointer;
}

.main-button:hover,
.context-menu-button:hover {
  background-color: var(--buttons-hover-color);
}

.main-button {
  width: 120px;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.main-button.active {
  background-color: var(--tab-buttons-active-color);
}

.context-menu-button {
  width: 30px;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  margin-left: 0;
  padding: 0.5rem 0.75rem;
  border-left: none !important;
}

.context-menu-button.active {
  background-color: var(--tab-buttons-active-color);
}

.context-menu-icon {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.context-menu-icon .dot {
  width: 4px;
  height: 4px;
  background-color: var(--primary-text-color);
  border-radius: 50%;
}


.context-menu-item i {
  font-size: 1.2rem;
}

.context-menu li:hover {
  color: var(--links-color);
}
</style>
