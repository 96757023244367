//import { fabric } from 'fabric';


export function alignCloseObjects(activeObjects) {
    if (activeObjects.length === 2) {
        const maxPixelsDiff = 19;

        const obj1 = activeObjects[0];
        const obj2 = activeObjects[1];

        const bounds1 = obj1.getBoundingRect(true);
        const bounds2 = obj2.getBoundingRect(true);

        const angle1StartIdx = ((360 + obj1.angle) / 90) % 4;
        const angle2StartIdx = ((360 + obj2.angle) / 90) % 4;

        const pos1ArrayLeft = [obj1.left, obj1.left - bounds1.width, obj1.left - bounds1.width, obj1.left];
        const pos1ArrayTop = [obj1.top, obj1.top, obj1.top - bounds1.height, obj1.top - bounds1.height];
        const pos2ArrayLeft = [obj2.left, obj2.left - bounds2.width, obj2.left - bounds2.width, obj2.left];
        const pos2ArrayTop = [obj2.top, obj2.top, obj2.top - bounds2.height, obj2.top - bounds2.height];

        const obj1Edges = {
            left: pos1ArrayLeft[angle1StartIdx],
            top: pos1ArrayTop[angle1StartIdx],
            right: pos1ArrayLeft[angle1StartIdx] + bounds1.width,
            bottom: pos1ArrayTop[angle1StartIdx] + bounds1.height,
        };

        const obj2Edges = {
            left: pos2ArrayLeft[angle2StartIdx],
            top: pos2ArrayTop[angle2StartIdx],
            right: pos2ArrayLeft[angle2StartIdx] + bounds2.width,
            bottom: pos2ArrayTop[angle2StartIdx] + bounds2.height,
        };

        console.log(obj1Edges);
        console.log(obj2Edges);
        console.log("************");
        console.log(obj1);
        console.log(obj2);

        if (Math.abs(obj1Edges.left - obj2Edges.right) <= maxPixelsDiff) {
            const pos = obj2Edges.right;
            switch (angle1StartIdx) {
                case 0:
                    obj1.left = pos;
                    break;
                case 1:
                    obj1.left = pos + bounds1.width;
                    break;
                case 2:
                    obj1.left = pos + bounds1.width;
                    break;
                case 3:
                    obj1.left = pos;
                    break;
            }
        } else if (Math.abs(obj2Edges.left - obj1Edges.right) <= maxPixelsDiff) {
            const pos = obj1Edges.right;
            switch (angle2StartIdx) {
                case 0:
                    obj2.left = pos;
                    break;
                case 1:
                    obj2.left = pos + bounds2.width;
                    break;
                case 2:
                    obj2.left = pos + bounds2.width;
                    break;
                case 3:
                    obj2.left = pos;
                    break;
            }
        } else if (Math.abs(obj1Edges.top - obj2Edges.bottom) <= maxPixelsDiff) {
            const pos = obj2Edges.bottom;
            switch (angle1StartIdx) {
                case 0:
                    obj1.top = pos;
                    break;
                case 1:
                    obj1.top = pos;
                    break;
                case 2:
                    obj1.top = pos + bounds1.height;
                    break;
                case 3:
                    obj1.top = pos + bounds1.height;
                    break;
            }
        } else if (Math.abs(obj2Edges.top - obj1Edges.bottom) <= maxPixelsDiff) {
            const pos = obj1Edges.bottom;
            switch (angle2StartIdx) {
                case 0:
                    obj2.top = pos;
                    break;
                case 1:
                    obj2.top = pos;
                    break;
                case 2:
                    obj2.top = pos + bounds2.height;
                    break;
                case 3:
                    obj2.top = pos + bounds2.height;
                    break;
            }
        }

        activeObjects.forEach((obj) => {
            obj.setCoords();
        });
    }
}

export function alignLeft(activeObjects) {
    if (activeObjects.length > 0) {
        // Find the minimum left position in the global coordinate space
        const minLeft = Math.min(...activeObjects.map(obj => {
            const bounds = obj.getBoundingRect(true);
            return bounds.left;
        }));

        activeObjects.forEach((obj) => {
            // Get the bounding rectangle in global coordinates
            const bounds = obj.getBoundingRect(true);
            const offsetX = bounds.left - obj.left;

            // Set the new left position
            obj.set('left', minLeft - offsetX);

            // Update the object's coordinates
            obj.setCoords();
        });
    }
}

export function alignTop(activeObjects) {
    if (activeObjects.length > 0) {
        const minTop = Math.min(...activeObjects.map(obj => {
            const bounds = obj.getBoundingRect(true);
            return bounds.top;
        }));

        activeObjects.forEach((obj) => {
            const bounds = obj.getBoundingRect(true);
            const offsetY = bounds.top - obj.top;

            obj.set('top', minTop - offsetY);
            obj.setCoords();
        });
    }
}

export function alignRight(activeObjects) {
    if (activeObjects.length > 0) {
        // Find the maximum right position in the global coordinate space
        const maxRight = Math.max(...activeObjects.map(obj => {
            const bounds = obj.getBoundingRect(true);
            return bounds.left + bounds.width;
        }));

        activeObjects.forEach((obj) => {
            // Get the bounding rectangle in global coordinates
            const bounds = obj.getBoundingRect(true);
            const offsetX = bounds.left - obj.left;

            // Set the new left position
            obj.set('left', maxRight - bounds.width - offsetX);

            // Update the object's coordinates
            obj.setCoords();
        });
    }
}

export function alignBottom(activeObjects) {
    if (activeObjects.length > 0) {
        // Find the maximum bottom position in the global coordinate space
        const maxBottom = Math.max(...activeObjects.map(obj => {
            const bounds = obj.getBoundingRect(true);
            return bounds.top + bounds.height;
        }));

        activeObjects.forEach((obj) => {
            // Get the bounding rectangle in global coordinates
            const bounds = obj.getBoundingRect(true);
            const offsetY = bounds.top - obj.top;

            // Set the new top position
            obj.set('top', maxBottom - bounds.height - offsetY);

            // Update the object's coordinates
            obj.setCoords();
        });
    }
}


export function alignCenterVertical(activeObjects) {
    if (activeObjects.length > 0) {
        const minTop = Math.min(...activeObjects.map(obj => {
            const bounds = obj.getBoundingRect(true);
            return bounds.top;
        }));
        const maxBottom = Math.max(...activeObjects.map(obj => {
            const bounds = obj.getBoundingRect(true);
            return bounds.top + bounds.height;
        }));
        const avgCenterY = (minTop + maxBottom) / 2;

        activeObjects.forEach((obj) => {
            const bounds = obj.getBoundingRect(true);
            const offsetY = bounds.top - obj.top;

            obj.set('top', avgCenterY - bounds.height / 2 - offsetY);
            obj.setCoords();
        });
    }
}

export function alignCenterHorizontal(activeObjects) {
    if (activeObjects.length > 0) {
        const minLeft = Math.min(...activeObjects.map(obj => {
            const bounds = obj.getBoundingRect(true);
            return bounds.left;
        }));
        const maxRight = Math.max(...activeObjects.map(obj => {
            const bounds = obj.getBoundingRect(true);
            return bounds.left + bounds.width;
        }));
        const avgCenterX = (minLeft + maxRight) / 2;

        activeObjects.forEach((obj) => {
            const bounds = obj.getBoundingRect(true);
            const offsetX = bounds.left - obj.left;

            obj.set('left', avgCenterX - bounds.width / 2 - offsetX);
            obj.setCoords();
        });
    }
}

