<template>
  <button @click="alignClose" :disabled="disable_buttons" title="Przysuń (sklej) bliskie elementy" class="rect-button act-button-position" style="margin-left: 8px;">
    <img src="@/assets/icons/attraction.svg" alt="Sklej bliskie elementy"/> <!-- :class="{ 'inverted': $theme.invertIcon }"/> -->
  </button>
  <button @click="alignToLeft" :disabled="disable_buttons" title="Wyrównaj do lewej" class="rect-button act-button-position">
    <img src="@/assets/icons/to_left.svg" alt="Wyrównanie do lewej" :class="{ 'inverted': $theme.invertIcon }"/>
  </button>
  <button @click="alignToCenterHorizontal" :disabled="disable_buttons" title="Wyrównaj do środka w pionie" class="rect-button act-button-position">
    <img src="@/assets/icons/center_vertical.svg" alt="Wyrównanie do środka w pionie" :class="{ 'inverted': $theme.invertIcon }"/>
  </button>
  <button @click="alignToRight" :disabled="disable_buttons" title="Wyrównaj do prawej" class="rect-button act-button-position">
    <img src="@/assets/icons/to_right.svg" alt="Wyrównanie do prawej" :class="{ 'inverted': $theme.invertIcon }"/>
  </button>
  <button @click="alignToTop" :disabled="disable_buttons" title="Wyrównaj do góry" class="rect-button act-button-position">
    <img src="@/assets/icons/to_top.svg" alt="Wyrównanie do góry" :class="{ 'inverted': $theme.invertIcon }"/>
  </button>
  <button @click="alignToCenterVertical" :disabled="disable_buttons" title="Wyrównaj do środka w poziomie" class="rect-button act-button-position">
    <img src="@/assets/icons/center_horizontal.svg" alt="Wyrównanie do środka w poziomie" :class="{ 'inverted': $theme.invertIcon }"/>
  </button>
  <button @click="alignToBottom" :disabled="disable_buttons" title="Wyrównaj do dołu" class="rect-button act-button-position" style="margin-right: 8px;">
    <img src="@/assets/icons/to_bottom.svg" alt="Wyrównanie do dołu" :class="{ 'inverted': $theme.invertIcon }"/>
  </button>
</template>

<script>
export default {
  name: 'ObjectsAlignment',
  emits: ['align-close', 'align-to-left', 'align-to-right', 'align-to-top', 'align-to-left', 'align-to-bottom', 'align-to-center-vertical', 'align-to-center-horizontal'],
  props: {
    disable_buttons:{
      type: Boolean,
      default: true,
      required: true,
    }
  },
  methods: {
    alignClose() {
      this.$emit('align-close');
    },
    alignToLeft() {
      this.$emit('align-to-left');
    },
    alignToRight() {
      this.$emit('align-to-right');
    },
    alignToTop() {
      this.$emit('align-to-top');
    },
    alignToBottom() {
      this.$emit('align-to-bottom');
    },
    alignToCenterVertical() {
      this.$emit('align-to-center-vertical');
    },
    alignToCenterHorizontal() {
      this.$emit('align-to-center-horizontal');
    }
  }
};
</script>

<style scoped>
@import '@/assets/themes.css';

</style>
